import { Injectable } from '@angular/core';

import {
  combineLatest,
  concat,
  concatMap,
  EMPTY,
  filter,
  of,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { globalActions } from '@libs/core/store';

import { dataModelActions, dataModelFeature } from '../data-model';
import { dataModelProjectFeature } from '../data-model-project';
import { dataModelVersionActions } from './data-model-version.actions';
import { DataModelWorkshopApiService } from '../../services';
import { dataModelVersionFeature } from './data-model-version.reducer';

@Injectable({
  providedIn: 'root',
})
export class DataModelVersionEffects {
  constructor(
    private _actions$: Actions,
    private _dataModelApiService: DataModelWorkshopApiService,
    private _store: Store,
  ) {}

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelVersionActions.loadList),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectSelectedItem),
      ),
      switchMap(([, dmwProject, dataModel]) => {
        if (!dmwProject || !dataModel) {
          return EMPTY;
        }
        return this._dataModelApiService
          .getDataModelVersionList$({
            dmwProjectId: dmwProject.id,
            dataModelId: dataModel.id,
          })
          .pipe(
            map((response) =>
              dataModelVersionActions.loadListSuccess({
                result: response,
              }),
            ),
            catchError((error) => {
              return of(dataModelVersionActions.loadListFailure({ error }));
            }),
            takeUntil(
              combineLatest([
                this._store.select(dataModelVersionFeature.selectRefreshing),
                this._store.select(dataModelVersionFeature.selectLoadingInitial),
              ]).pipe(
                filter(([refreshing, loadingInitial]) => !refreshing && !loadingInitial), // Stop when both are false
              ),
            ),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => dataModelVersionActions.resetState()),
    ),
  );

  changedParentSelect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelActions.setSelectedItem),
      startWith(null),
      pairwise(),
      filter(([actionPrev, actionCurrent]) => {
        const isSameItem = actionPrev?.item?.id === actionCurrent?.item?.id;
        return !isSameItem; // Exclude if the selected item hasn't changed
      }),
      concatMap(([, actionCurrent]) => {
        const isItemCleared = !actionCurrent?.item;

        if (isItemCleared) {
          return of(dataModelVersionActions.resetState());
        }

        return concat(
          of(dataModelVersionActions.resetState()),
          of(dataModelVersionActions.loadList()),
        );
      }),
    ),
  );
}
