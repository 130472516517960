import {
  HttpEvent,
  type HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NOTIFICATION_FAILURE_TOKEN, NOTIFICATION_SUCCESS_TOKEN } from '@libs/core/tokens';

import { NotificationService } from './notification.service';

export const notificationInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const notificationSuccess = request.context.get(NOTIFICATION_SUCCESS_TOKEN);
  const notificationFailure = request.context.get(NOTIFICATION_FAILURE_TOKEN);
  const notificationService = inject(NotificationService);

  return next(request).pipe(
    tap((event) => {
      if (notificationSuccess !== '' && event instanceof HttpResponse) {
        notificationService.success(notificationSuccess);
      }
    }),
    catchError((error) => {
      if (notificationFailure !== '') {
        notificationService.error(notificationFailure);
      }
      throw error;
    }),
  );
};
