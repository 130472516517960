import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  HostBinding,
  input,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';

import { OcpTranslatePipe } from '@ocp/fusion-cdk/translate';
import { OcpUtilityDomService } from '@ocp/utils/services';
import { OcpUtilsSignal } from '@ocp/utils/helpers';

import { OcpInput } from './input.model';

const OCP_FORM_FIELD_READONLY_CLASS = 'ocp-form-field-readonly';
const OCP_FORM_FIELD_HIDE_ERROR_CLASS = 'hide-error';

@Component({
  selector: 'ocp-input',
  standalone: true,
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormField, MatInput, MatLabel, ReactiveFormsModule, OcpTranslatePipe, MatTooltip],
  providers: [OcpUtilityDomService],
})
export class OcpInputComponent {
  @HostBinding('class.ocp-input') hostCssClass = true;

  configSig = input.required<OcpInput>({ alias: 'config' });

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _utilityDomService: OcpUtilityDomService,
  ) {
    effect(() => {
      const isReadOnly = this.configSig().readonly;
      this._utilityDomService.updateClass(
        this._elementRef,
        OCP_FORM_FIELD_READONLY_CLASS,
        isReadOnly,
      );
    });

    effect(() => {
      this._utilityDomService.updateClass(
        this._elementRef,
        OCP_FORM_FIELD_HIDE_ERROR_CLASS,
        this.configSig().appearanceParams.hideError ?? false,
      );
    });

    effect(() => {
      const { currentValue, formControl } = this.configSig();

      const value = currentValue?.();

      if (value != null) {
        formControl.reset(value);
      }
    });

    effect(() => {
      const control = this.configSig().formControl;
      const isControlDisabled = this.configSig().disabled();
      const isReadonly = this.configSig().readonly;

      isReadonly || isControlDisabled ? control.disable() : control.enable();
    });

    effect(() => {
      const { initialValue, formControl } = this.configSig();
      const valueToSet = OcpUtilsSignal.extract(initialValue);

      if (formControl.untouched && valueToSet && valueToSet !== formControl.value) {
        formControl.setValue(valueToSet, {
          emitEvent: false,
        });
      }
    });

    effect((onCleanup) => {
      const { formControl, reset$, initialValue } = this.configSig();

      const resetSub = reset$.subscribe(() => {
        const value = OcpUtilsSignal.extract(initialValue, false);

        formControl.reset(value);
      });
      onCleanup(() => resetSub.unsubscribe());
    });
  }

  // Handle input changes
  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.configSig().onInput?.(value);
  }
}
