import { Injectable } from '@angular/core';

import {
  catchError,
  debounceTime,
  filter,
  map,
  type Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import type {
  TTaskJobListRequest,
  TTaskJobListResponse,
  TTaskJobQueryField,
  TTaskJobSortField,
} from '../types';
import { TaskJobApiService } from '../services';
import { taskJobFeature } from './task-job.reducer';
import { taskJobActions } from './task-job.actions';

@Injectable({ providedIn: 'root' })
export class TaskJobEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _taskJobApiService: TaskJobApiService,
  ) {}

  loadTaskJobs$ = createEffect(() =>
    this._fetchTaskJobs$(
      [
        taskJobActions.setQueryData,
        taskJobActions.resetQueryFilter,
        taskJobActions.setQueryFilterDisabled,
      ],
      taskJobActions.loadListSuccess,
      taskJobActions.loadListFailure,
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(taskJobActions.loadList),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(taskJobFeature.selectPagination),
        this._store.select(taskJobFeature.selectSort),
        this._store.select(taskJobFeature.selectQuery),
        this._store.select(taskJobFeature.selectRecords),
      ),
      switchMap(([, projectId, pagination, sort, query, records]) => {
        if (projectId === null || Boolean(records.length)) {
          return of();
        }
        return this._taskJobApiService
          .getList$(
            projectId,
            createListPayload<TTaskJobSortField, TTaskJobQueryField, TTaskJobListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => taskJobActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(taskJobActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  loadListWithForce$ = createEffect(() =>
    this._actions$.pipe(
      ofType(taskJobActions.loadListWithForce),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(taskJobFeature.selectPagination),
        this._store.select(taskJobFeature.selectSort),
        this._store.select(taskJobFeature.selectQuery),
      ),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          return of();
        }
        return this._taskJobApiService
          .getList$(
            projectId,
            createListPayload<TTaskJobSortField, TTaskJobQueryField, TTaskJobListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => taskJobActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(taskJobActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  refreshTaskJobs$ = createEffect(() =>
    this._fetchTaskJobs$(
      [taskJobActions.setPageSize, taskJobActions.setPageIndex, taskJobActions.setSort],
      taskJobActions.refreshListSuccess,
      taskJobActions.refreshListFailure,
    ),
  );

  startJobs$ = createEffect(() =>
    this._actions$.pipe(
      ofType(taskJobActions.startJobs),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(this._store.select(globalFeature.selectOpenedProjectId)),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([payload, projectId]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._taskJobApiService.startJobs$(projectId, { ...payload.payload }).pipe(
          map(() => taskJobActions.loadListWithForce()),
          catchError(() => {
            return of();
          }),
        );
      }),
    ),
  );

  retryJobs$ = createEffect(() =>
    this._actions$.pipe(
      ofType(taskJobActions.retryJobs),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(this._store.select(globalFeature.selectOpenedProjectId)),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([payload, projectId]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._taskJobApiService.retryJobs$(projectId, { ...payload.payload }).pipe(
          map(() => taskJobActions.loadList()),
          catchError(() => {
            return of();
          }),
        );
      }),
    ),
  );

  cancelJobs$ = createEffect(() =>
    this._actions$.pipe(
      ofType(taskJobActions.cancelJobs),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(this._store.select(globalFeature.selectOpenedProjectId)),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([payload, projectId]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._taskJobApiService.cancelJobs$(projectId, { ...payload.payload }).pipe(
          map(() => taskJobActions.loadList()),
          catchError(() => {
            return of();
          }),
        );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => taskJobActions.resetState()),
    ),
  );

  private _fetchTaskJobs$(
    actions: ActionCreator[],
    successCallback: (response: { result: TTaskJobListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(taskJobFeature.selectPagination),
        this._store.select(taskJobFeature.selectSort),
        this._store.select(taskJobFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._taskJobApiService
          .getList$(
            projectId,
            createListPayload<TTaskJobSortField, TTaskJobQueryField, TTaskJobListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
