import { ChangeDetectionStrategy, Component, computed, OnInit, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { type OcpButtonRealisation } from '@ocp/ui-kit/button';
import { OcpSelectConfig } from '@ocp/ui-kit/form';

import { taskActions, taskFeature, type TTask } from '@libs/modules/task';
import { documentFeature, TDocument } from '@libs/modules/document';
import { projectSettingsFeature } from '@libs/modules/project-settings';
import { documentJobActions, DocumentJobTableComponent } from '@libs/modules/document-job';
import { taskJobActions, TaskJobTableComponent } from '@libs/modules/task-job';

import { TaskManagerActions, TaskManagerActionsComponent } from '../../components';

@Component({
  selector: 'lib-task-manager',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './task-manager.component.html',
  styleUrl: './task-manager.component.scss',
  imports: [
    DocumentJobTableComponent,
    TaskJobTableComponent,
    OcpTranslatePipe,
    MatTabsModule,
    TaskManagerActionsComponent,
    OcpTranslationSectionDirective,
  ],
})
export class TaskManagerComponent implements OnInit {
  public readonly documentJobsTitle = 'DOCUMENT_JOB_TITLE';
  public readonly taskJobsTitle = 'TASK_JOB_TITLE';

  public taskJobActionsConfig!: TaskManagerActions<{ taskList: TTask }>;
  public documentJobActionsConfig!: TaskManagerActions<{
    taskList: TTask;
    document: TDocument;
  }>;

  private _startTaskActionButton: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this._startJobs(),
      disabled: computed(() => !this._selectedTaskRunId()),
    },
    visualisationConfig: {
      appearance: 'stroked',
      icon: {
        icon: 'play_arrow',
      },
      label: 'ACTIONS.START',
    },
  };
  private _startDocTaskActionButton: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this._startDocumentJobs(),
      disabled: computed(() => !this._selectedTaskRunId() && !this._selectedDocTaskRunId()),
    },
    visualisationConfig: {
      appearance: 'stroked',
      icon: {
        icon: 'play_arrow',
      },
      label: 'ACTIONS.START',
    },
  };
  private _selectedDataModelVersionId = toSignal(
    this._store.select(projectSettingsFeature.selectEntity),
    {
      requireSync: true,
    },
  )()?.dataModelWorkshop?.dataModelVersionId;
  private _taskListSig = toSignal(this._store.select(taskFeature.selectRecords), {
    requireSync: true,
  });
  private _selectedTaskRunId = signal<number | null>(null);
  private _selectedDocTaskRunId = signal<number | null>(null);
  private _selectedDocumentId = signal<number | null>(null);

  constructor(private readonly _store: Store) {
    this._initializeTaskManagerActions();
  }

  ngOnInit(): void {
    this._store.dispatch(taskActions.loadList());
  }

  private _initializeTaskManagerActions(): void {
    this.taskJobActionsConfig = new TaskManagerActions({
      controls: {
        taskList: new OcpSelectConfig({
          label: 'SELECT_TASK_PLACEHOLDER',
          objectId: crypto.randomUUID(),
          initialValue: {
            type: 'property',
            prop: 'name',
            value: null,
          },
          data: computed(() =>
            this._taskListSig().filter((task) => {
              if (task.inputs?.some((inputItem) => inputItem.type === 'file')) {
                return;
              }
              return task;
            }),
          ),
          displayFn: (value) => value?.name ?? '',
          compareFn: (value1, value2) => value1?.name === value2?.name,
          onSelect: (value) => this._selectedTaskRunId.set(value.id),
        }),
      },
      startActionButton: this._startTaskActionButton,
    });
    this.documentJobActionsConfig = new TaskManagerActions({
      controls: {
        document: new OcpSelectConfig({
          label: 'SELECT_DOCUMENT_PLACEHOLDER',
          objectId: crypto.randomUUID(),
          initialValue: {
            type: 'property',
            prop: 'name',
            value: null,
          },
          data: toSignal(this._store.select(documentFeature.selectRecords), { requireSync: true }),
          displayFn: (value) => value?.name ?? '',
          compareFn: (value1, value2) => value1?.name === value2?.name,
          onSelect: (value) => this._selectedDocumentId.set(value.id),
        }),
        taskList: new OcpSelectConfig({
          label: 'SELECT_TASK_PLACEHOLDER',
          objectId: crypto.randomUUID(),
          initialValue: {
            type: 'property',
            prop: 'name',
            value: null,
          },
          data: computed(() =>
            this._taskListSig().filter((task) => {
              if (task.inputs?.some((inputItem) => inputItem.type === 'file')) {
                return task;
              }
              return;
            }),
          ),
          displayFn: (value) => value?.name ?? '',
          compareFn: (value1, value2) => value1?.name === value2?.name,
          onSelect: (value) => this._selectedDocTaskRunId.set(value.id),
        }),
      },
      startActionButton: this._startDocTaskActionButton,
    });
  }

  private _startJobs(): void {
    const selectedTaskId = this._selectedTaskRunId();
    if (selectedTaskId != null) {
      this._store.dispatch(
        taskJobActions.startJobs({
          payload: {
            data: [
              {
                taskId: selectedTaskId.toString(),
                datamodelVersionId: this._selectedDataModelVersionId?.toString(),
              },
            ],
          },
        }),
      );
    }
    this._selectedTaskRunId.set(null);
    this.taskJobActionsConfig.controls.taskList.reset();
  }

  private _startDocumentJobs(): void {
    const selectedTaskId = this._selectedDocTaskRunId();
    const selectedDocumentId = this._selectedDocumentId();
    if (selectedTaskId != null && selectedDocumentId != null) {
      this._store.dispatch(
        documentJobActions.startJobs({
          payload: {
            data: [
              {
                taskId: selectedTaskId.toString(),
                documentId: selectedDocumentId.toString(),
                datamodelVersionId: this._selectedDataModelVersionId?.toString(),
              },
            ],
          },
        }),
      );
    }
    this._selectedDocTaskRunId.set(null);
    this._selectedDocumentId.set(null);
    this.documentJobActionsConfig.controls.document.reset();
    this.documentJobActionsConfig.controls.taskList.reset();
  }
}
