<mat-tab-group animationDuration="0">
  <mat-tab label="{{ documentJobsTitle | ocpTranslate }}">
    <ng-template matTabContent ocpTranslateSection="" ocpTranslateSectionRoot>
      <lib-task-manager-actions [config]="documentJobActionsConfig" />
      <lib-document-job />
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ taskJobsTitle | ocpTranslate }}">
    <ng-template matTabContent ocpTranslateSection="" ocpTranslateSectionRoot>
      <lib-task-manager-actions [config]="taskJobActionsConfig" />
      <lib-task-job />
    </ng-template>
  </mat-tab>
</mat-tab-group>
