import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { ENVIRONMENT_SERVICE_TOKEN } from '@libs/core/environment';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type {
  TDataModelClassListRequest,
  TDataModelClassListResponse,
  TDataModelClassListUrlParams,
  TDataModelListRequest,
  TDataModelListResponse,
  TDataModelListUrlParams,
  TDataModelProjectListResponse,
  TDataModelVersionListResponse,
  TDataModelVersionListUrlParams,
} from '../types';

@Injectable({ providedIn: 'root' })
export class DataModelWorkshopApiService {
  private readonly _environmentService = inject(ENVIRONMENT_SERVICE_TOKEN);

  private readonly _urlBuilder = new OcpUrlBuilder(
    `${this._environmentService.getProp('API_PREFIX')}/dmw-projects`,
  );

  constructor(private readonly _http: HttpClient) {}

  public getDataModelProjectList$(): Observable<TDataModelProjectListResponse> {
    const url = this._urlBuilder.buildUrl(['list']);

    return this._http.get<TDataModelProjectListResponse>(url, {
      context: createHttpContext({
        VALIDATE_REQUEST: false,
        RESPONSE_SCHEMA: SCHEMAS['DMWProjectListResponse'],
      }),
    });
  }

  public getDataModelList$(
    urlParams: TDataModelListUrlParams,
    payload?: TDataModelListRequest,
  ): Observable<TDataModelListResponse> {
    const url = this._urlBuilder.buildUrl([urlParams.dmwProjectId, 'datamodels', 'list']);

    return this._http.post<TDataModelListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['DataModelListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['DataModelListResponse'],
      }),
    });
  }

  public getDataModelVersionList$(
    urlParams: TDataModelVersionListUrlParams,
  ): Observable<TDataModelVersionListResponse> {
    const url = this._urlBuilder.buildUrl([
      urlParams.dmwProjectId,
      'datamodels',
      urlParams.dataModelId,
      'versions',
      'list',
    ]);

    return this._http.get<TDataModelVersionListResponse>(url, {
      context: createHttpContext({
        VALIDATE_REQUEST: false,
        RESPONSE_SCHEMA: SCHEMAS['DataModelVersionListResponse'],
      }),
    });
  }

  public getDataModelClassList$(
    urlParams: TDataModelClassListUrlParams,
    payload?: TDataModelClassListRequest,
  ): Observable<TDataModelClassListResponse> {
    const url = this._urlBuilder.buildUrl([
      urlParams.dmwProjectId,
      'datamodels',
      urlParams.dataModelId,
      'versions',
      urlParams.dataModelVersionId,
      'classes',
      'list',
    ]);

    return this._http.post<TDataModelClassListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['DataModelClassListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['DataModelClassListResponse'],
      }),
    });
  }
}
