import { createFeature, createReducer, on } from '@ngrx/store';

import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';
import type { OcpStoreEntityList } from '@ocp/utils-advanced/types';

import type { TDataModelVersion } from '../../types';
import { dataModelVersionActions } from './data-model-version.actions';
import { DATA_MODEL_VERSION_STORE_OPTIONS } from './data-model-version-store-optiosn.constant';

type TStateExtend = {
  recordsAll: TDataModelVersion[];
};
type TInitialState = OcpStoreEntityList<
  TDataModelVersion,
  typeof DATA_MODEL_VERSION_STORE_OPTIONS
> &
  TStateExtend;

const defaultState = getDefaultStoreEntityList<TDataModelVersion>()(
  DATA_MODEL_VERSION_STORE_OPTIONS,
);
const initialState: TInitialState = {
  ...defaultState,
  recordsAll: [],
};

const defaultHandlers = getEntityListHandlers<TDataModelVersion, TStateExtend>()(
  DATA_MODEL_VERSION_STORE_OPTIONS,
);

const dataModelVersionReducer = createReducer(
  initialState,
  on(dataModelVersionActions.loadList, (state) => defaultHandlers.loadList(state)),
  on(dataModelVersionActions.loadListSuccess, (state, { result: response }) => ({
    ...defaultHandlers.loadListSuccess(state, response),
    recordsAll: response,
  })),
  on(dataModelVersionActions.setQueryData, (state, { queryData }) => ({
    ...state,
    records: state.recordsAll.filter((el) =>
      queryData.some((query) =>
        String(el[query.field]).toLowerCase().includes(query.q.toLowerCase()),
      ),
    ),
  })),
  on(dataModelVersionActions.setSelectedItem, (state, { item }) => ({
    ...state,
    records: item ? state.recordsAll.filter((el) => el.id === item.id) : state.recordsAll,
    selectedItem: item,
  })),
  on(dataModelVersionActions.resetState, () => initialState),
);

export const dataModelVersionFeature = createFeature({
  name: 'dataModelVersion',
  reducer: dataModelVersionReducer,
});
