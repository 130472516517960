<ng-container [ocpTranslateSection]="config.translateSection">
  <div class="dialog-title" mat-dialog-title>
    @if (config.titleConfig) {
      <ng-container *ocpComponent="OcpLabelBasicComponent; config: config.titleConfig" />
    }

    @if (config.showClose) {
      <ocp-button-universal [config]="closeDialogButtonConfig" />
    }
  </div>

  @if (config.showSeparator) {
    <mat-divider />
  }

  <mat-dialog-content>
    <ng-container *ngComponentOutlet="config.body.component; inputs: config.body.inputs" />
  </mat-dialog-content>
</ng-container>
