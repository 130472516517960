import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { type OcpPaginationEvent, OcpPaginatorStandardConfig } from '@ocp/ui-kit/paginator';
import { OcpSearchConfig } from '@ocp/ui-kit/search';
import { OcpTableFeaturedComponent, type OcpTableFeaturedConfig } from '@ocp/ui-kit/table';
import { OcpScheduler } from '@ocp/utils/models';
import { OcpTranslatePipe } from '@ocp/fusion-cdk/translate';

import { DEFAULT_TABLE_CELL_EMPTY_VALUE } from '@libs/core/constants';
import { taskJobActions } from '@libs/modules/task-job';

import { documentJobActions, documentJobFeature } from '../../store';
import type { TDocumentJob, TDocumentJobQueryField, TDocumentJobSortField } from '../../types';
import {
  DEFAULT_DOCUMENT_JOB_TABLE_SEARCH_CONFIG,
  DOCUMENT_JOB_STATUS_TO_COLOR_CONFIG,
} from './constants';
import { TDocumentJobTableColumnKey } from './models';

@Component({
  selector: 'lib-document-job',
  standalone: true,
  templateUrl: './document-job-table.component.html',
  styleUrl: './document-job-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OcpTableFeaturedComponent],
})
export class DocumentJobTableComponent implements OnInit, OnDestroy {
  public tableConfig!: OcpTableFeaturedConfig<
    TDocumentJob,
    TDocumentJobTableColumnKey,
    TDocumentJobQueryField,
    TDocumentJobSortField
  >;

  @Output() readonly rowsSelected = new EventEmitter<TDocumentJob[]>();

  private _documentJobs$ = this._store.select(documentJobFeature.selectRecords);

  private _documentJobSig = toSignal(this._store.select(documentJobFeature.selectRecords), {
    requireSync: true,
  });

  private _scheduleHandler = new OcpScheduler(
    () => this._store.dispatch(documentJobActions.loadListWithForce()),
    2000,
  );

  constructor(private readonly _store: Store) {
    this._initializeTableOptions();
    this._initializeTablePaginator();
    this._initializeTableSearch();

    this._scheduleHandler.listen(
      this._documentJobs$.pipe(map((jobs) => jobs.some((job) => job.taskStatus === 'RUNNING'))),
      this._store.select(documentJobFeature.selectLoadingInitial),
    );
  }

  ngOnInit(): void {
    this._store.dispatch(documentJobActions.loadList());
  }

  ngOnDestroy(): void {
    this._scheduleHandler.destroy();
  }

  private _initializeTableOptions(): void {
    this.tableConfig = {
      tableConfig: {
        data: this._documentJobSig,
        translateSection: 'DOCUMENT_JOB_TABLE',
        titleKey: 'HEADER_TITLE',
        cellEmptyValue: DEFAULT_TABLE_CELL_EMPTY_VALUE,
        sortConfig: {
          sort: toSignal(this._store.select(documentJobFeature.selectSort), { requireSync: true }),
          onSortChange: (sort) =>
            this._store.dispatch(documentJobActions.setSort({ sort, refreshBehavior: 'replace' })),
        },
        loadingInitial: toSignal(this._store.select(documentJobFeature.selectLoadingInitial), {
          requireSync: true,
        }),
        refreshing: toSignal(this._store.select(documentJobFeature.selectRefreshing), {
          requireSync: true,
        }),
        columns: [
          {
            def: 'name',
            header: 'NAME',
            isSortable: true,
            getValue: (documentJob: TDocumentJob) => documentJob.name,
          },
          {
            def: 'datamodelName',
            header: 'DATAMODEL_NAME',
            isSortable: true,
            getValue: (documentJob: TDocumentJob) => documentJob.datamodelName,
          },
          {
            def: 'datamodelVersionId',
            header: 'DATAMODEL_VERSION_ID',
            isSortable: true,
            getValue: (documentJob: TDocumentJob) => documentJob.datamodelVersionId,
          },
          {
            def: 'taskName',
            header: 'TASK_NAME',
            isSortable: true,
            getValue: (documentJob: TDocumentJob) => documentJob.taskName,
          },
          {
            def: 'taskStatus',
            header: 'TASK_STATUS',
            isSortable: true,
            getValue: (documentJob: TDocumentJob) => documentJob.taskStatus,
            pipe: OcpTranslatePipe,
            getColor: (documentJob: TDocumentJob) =>
              (documentJob.taskStatus &&
                DOCUMENT_JOB_STATUS_TO_COLOR_CONFIG[documentJob.taskStatus].labelColor) ??
              'on-surface',
            typography: 'title-small',
          },
          {
            type: 'progress',
            def: 'taskProgress',
            header: 'TASK_PROGRESS',
            getValue: (documentJob: TDocumentJob) => Number(documentJob.taskProgress),
            getProgressBarColor: (documentJob: TDocumentJob) =>
              (documentJob.taskStatus &&
                DOCUMENT_JOB_STATUS_TO_COLOR_CONFIG[documentJob.taskStatus].progressBarColor) ??
              'surface-dim',
          },
        ],
        actionColumn: {
          def: 'actions',
          header: '',
          data: [
            {
              iconData: {
                icon: 'cancel',
              },
              tooltip: 'CANCEL_BUTTON_TOOLTIP',
              getDisabled: (documentJob: TDocumentJob) => documentJob.taskStatus !== 'RUNNING',
              action: (documentJob: TDocumentJob) =>
                this._store.dispatch(
                  taskJobActions.cancelJobs({
                    payload: {
                      ids: [documentJob.id.toString()],
                    },
                  }),
                ),
            },
            {
              iconData: {
                icon: 'restart_alt',
              },
              tooltip: 'RESTART_BUTTON_TOOLTIP',
              getDisabled: (documentJob: TDocumentJob) =>
                !(documentJob.taskStatus === 'FAILED' || documentJob.taskStatus === 'CANCELLED'),
              action: (documentJob: TDocumentJob) =>
                this._store.dispatch(
                  taskJobActions.retryJobs({
                    payload: {
                      ids: [documentJob.id.toString()],
                    },
                  }),
                ),
            },
          ],
        },
      },
    };
  }

  private _initializeTablePaginator(): void {
    this.tableConfig.paginationConfig = {
      type: 'standard',
      config: new OcpPaginatorStandardConfig({
        pagination: toSignal(this._store.select(documentJobFeature.selectPagination), {
          requireSync: true,
        }),
        onPaginationChange: (event) => this._handlePaginationChange(event),
      }),
    };
  }

  private _initializeTableSearch(): void {
    this.tableConfig.searchConfig = new OcpSearchConfig<TDocumentJobQueryField>({
      ...DEFAULT_DOCUMENT_JOB_TABLE_SEARCH_CONFIG,
      query: toSignal(this._store.select(documentJobFeature.selectQuery), { requireSync: true }),
      onQueryDataChange: (queryData) =>
        this._store.dispatch(documentJobActions.setQueryData({ queryData })),
      onResetFilter: () => this._store.dispatch(documentJobActions.resetQueryFilter()),
      onToggleFilterDisableState: (isDisabled) =>
        this._store.dispatch(documentJobActions.setQueryFilterDisabled({ isDisabled })),
    });
  }

  private _handlePaginationChange(event: OcpPaginationEvent): void {
    switch (event.type) {
      case 'pageSize':
        this._store.dispatch(
          documentJobActions.setPageSize({
            pageSize: event.value,
            refreshBehavior: event.behavior,
          }),
        );
        break;
      case 'pageIndex':
        this._store.dispatch(
          documentJobActions.setPageIndex({
            pageIndex: event.value,
            refreshBehavior: event.behavior,
          }),
        );
        break;
    }
  }
}
