import { Injectable } from '@angular/core';

import { IconService } from './icon.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  constructor(
    private _iconService: IconService,
    private _languageService: LanguageService,
  ) {
    this._iconService.registerCustomSvgIcons();
    this._languageService.initialize();
  }
}
