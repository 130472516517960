<div class="table-container">
  @if (configSig().refreshing) {
    <ocp-progress-bar [config]="progressBarConfig"></ocp-progress-bar>
  }

  @if (configSig().loadingInitial?.()) {
    <ocp-progress-spinner></ocp-progress-spinner>
  } @else {
    <table
      mat-table
      class="table"
      [dataSource]="dataSourceSig()"
      fixedLayout
      matSort
      [matSortActive]="sortSig().active"
      [matSortDirection]="sortSig().direction"
      [ocpTranslateSection]="configSig().translateSection || 'TABLE'"
      (matSortChange)="handleSortChange($event)"
    >
      @if (configSig().isSelectable) {
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="select-cell">
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="select-cell">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
      }
      @for (column of configSig().columns; track column) {
        <ng-container [matColumnDef]="column.def" [stickyEnd]="column.isStickyEnd">
          @if (column.isSortable) {
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              {{ column.header | ocpTranslate }}
            </th>
          } @else {
            <th mat-header-cell *matHeaderCellDef>
              {{ column.header | ocpTranslate }}
            </th>
          }
          <td
            mat-cell
            *matCellDef="let item"
            [class]="column.class"
            [matTooltipDisabled]="!column.needTooltip"
            [matTooltip]="column.getValue(item)"
          >
            <ocp-table-cell
              [config]="column"
              [item]="item"
              [defaultValue]="configSig().cellEmptyValue ?? ''"
            />
          </td>
        </ng-container>
      }

      @let actionsColumn = actionColumnSig();

      @if (actionsColumn) {
        <ng-container [matColumnDef]="actionsColumn.def" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="actions-cell">
            <span class="actions-cell-header">
              {{ actionsColumn.header | ocpTranslate }}
            </span>
          </th>
          <td mat-cell *matCellDef="let item" class="actions-cell">
            <div class="actions-table-cell">
              @let collapseToMenu = actionsColumn.collapseToMenu;

              @if (collapseToMenu) {
                @let menuConfig = convertActionsToMenu(actionsColumn.data, item);
                <ocp-actions-menu [config]="menuConfig" />
              } @else {
                @for (action of actionsColumn.data; track action.iconData) {
                  @let iconData = action.getIconData?.(item) ?? action.iconData;
                  @let disabled = action.getDisabled?.(item) ?? action.disabled?.();
                  @let tooltip = action.getTooltip?.(item) ?? action.tooltip ?? '';

                  <button
                    class="ocp-button-icon"
                    mat-icon-button
                    [matTooltip]="tooltip ? (tooltip | ocpTranslate) : ''"
                    (click)="$event.stopPropagation(); action.action(item)"
                    [disabled]="disabled"
                    [ngClass]="{ active: configSig().compareFn?.(action.activeItem?.(), item) }"
                    [color]="iconData.color | ocpSigExtract"
                  >
                    <ocp-icon-basic [iconData]="iconData"></ocp-icon-basic>
                  </button>
                }
              }
            </div>
          </td>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="displayedColumnsSig(); sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnsSig()"
        [ngClass]="{ selected: isRowSelected(row) }"
        (click)="handleRowClick(row)"
      ></tr>
    </table>
  }
</div>
