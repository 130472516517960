import { NgClass } from '@angular/common';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  HostBinding,
  input,
  ViewChild,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { type MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortModule, type Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { OcpActionMenuConfig, OcpActionsMenuComponent } from '@ocp/ui-kit/menu';
import { OcpProgressSpinnerComponent } from '@ocp/ui-kit/progress-spinner';
import { OcpProgressBarComponent, OcpProgressBarConfig } from '@ocp/ui-kit/progress-bar';
import { OcpIconBasicComponent } from '@ocp/ui-kit/icon';
import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { OcpSigExtractPipe } from '@ocp/utils/pipes';
import type { OcpSort, OcpSortOrder } from '@ocp/utils/types';

import { OcpTableCellComponent } from './table-cell';
import type { OcpTableBasicActionColumnData, OcpTableBasicConfig } from './types';

@Component({
  selector: 'ocp-table-basic',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './table-basic.component.html',
  styleUrl: './table-basic.component.scss',
  imports: [
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatCheckboxModule,
    NgClass,
    MatIconButton,
    OcpTranslationSectionDirective,
    OcpTranslatePipe,
    OcpIconBasicComponent,
    OcpTableCellComponent,
    OcpProgressBarComponent,
    OcpProgressSpinnerComponent,
    OcpSigExtractPipe,
    OcpActionsMenuComponent,
  ],
})
export class OcpTableBasicComponent<TEntity, TColumnDef extends string, TSortField extends string>
  implements AfterViewInit
{
  @HostBinding('class.ocp-table-basic') hostCssClass = true;
  configSig = input.required<OcpTableBasicConfig<TEntity, TColumnDef, TSortField>>({
    alias: 'config',
  });

  progressBarConfig = new OcpProgressBarConfig({
    isVisible: computed(() => this.configSig().refreshing?.() ?? false),
  });

  selection = new SelectionModel<TEntity>(true, []);

  displayedColumnsSig = computed(() => {
    const columns = this.configSig().columns.map((column) => column.def);
    const actionsDef = this.configSig().actionColumn?.def;
    if (actionsDef !== undefined) {
      columns.push(actionsDef);
    }
    return this.configSig().isSelectable === true ? ['select', ...columns] : columns;
  });
  actionColumnSig = computed(() => this.configSig().actionColumn);
  dataSourceSig = computed(() => {
    const data = this.configSig().data();
    if (data instanceof MatTableDataSource) {
      return data as MatTableDataSource<TEntity, MatPaginator>;
    }
    return new MatTableDataSource(data);
  });
  sortSig = computed<Sort>(() => this._transformFromOcpSort(this.configSig().sortConfig?.sort()));

  @ViewChild(MatSort) sortRef: MatSort | null = null;

  constructor() {
    this.selection.changed
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.configSig().onSelect?.(this.selection.selected));

    effect(() => {
      this._attachSort(this.dataSourceSig());
    });
  }

  public ngAfterViewInit(): void {
    this._attachSort(this.dataSourceSig());
  }

  public isRowSelected(row: TEntity): boolean {
    return this.configSig().tableActions?.isRowSelected?.(row) ?? false;
  }

  public handleRowClick(row: TEntity): void {
    this.configSig().onRowClick?.(row);
  }

  public handleSortChange(sort: Sort): void {
    const ocpSort = this._transformToOcpSort(sort);
    if (ocpSort) {
      this.configSig().sortConfig?.onSortChange?.(ocpSort);
    }

    if (!this.configSig().sortConfig?.comparator) {
      return;
    }

    const data = this.dataSourceSig().data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourceSig().data = data;
      return;
    }

    this.dataSourceSig().data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return (
        this.configSig().sortConfig?.comparator?.[sort.active as TSortField]?.(a, b, isAsc) ?? 0
      );
    });
  }

  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSourceSig().data.length;
  }

  public toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceSig().data);
  }

  public convertActionsToMenu(
    actions: OcpTableBasicActionColumnData<TEntity>[],
    entity: TEntity,
  ): OcpActionMenuConfig {
    return new OcpActionMenuConfig({
      triggerIcon: { icon: 'more_horiz', color: 'on-surface-variant' },
      showSeparator: true,
      position: { x: 'before', y: 'below' },
      menuItems: actions.map((action) => {
        return {
          item: {
            actionsConfig: {
              callback: () => action.action(entity),
            },
            visualisationConfig: {
              appearance: 'menu',
              icon: action.iconData,
              label: action.label,
            },
            type: 'button',
          },
        };
      }),
    });
  }

  private _attachSort(source: MatTableDataSource<TEntity>): void {
    if (this.sortRef === null) {
      return;
    }
    if (this.configSig().useDefaultSort ?? false) {
      source.sort = this.sortRef;
    }
  }

  private _transformToOcpSort(sort: Sort): OcpSort<TSortField> | undefined {
    if (sort.direction === 'asc' || sort.direction === 'desc') {
      return { order: sort.direction.toUpperCase() as OcpSortOrder, by: sort.active as TSortField };
    } else {
      return undefined;
    }
  }

  private _transformFromOcpSort(sort: OcpSort<TSortField> | undefined): Sort {
    if (!sort) {
      return { direction: '', active: '' };
    }

    return { direction: sort.order.toLowerCase() as SortDirection, active: sort.by };
  }
}
