@let displayFn = configSig().displayFn;
@let label = configSig().label;
@let formControl = configSig().formControl;
<!--@let readonly = isReadonly();-->
@let data = configSig().data();
@let appearance = configSig().appearance;
@let isDataLoading = configSig().isDataLoading?.();

<mat-form-field [appearance]="appearance">
  @if (label) {
    <mat-label>{{ label | ocpTranslate }}</mat-label>
  }
  <input
    type="text"
    matInput
    [formControl]="formControl"
    (input)="onInputChange($event)"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    [requireSelection]="true"
    [autoSelectActiveOption]="true"
    [displayWith]="displayFn"
    [class]="panelClass"
    #auto="matAutocomplete"
  >
    @for (item of data; track item) {
      <mat-option [value]="item">
        {{ displayFn(item) }}
      </mat-option>
    }
  </mat-autocomplete>

  @if (isDataLoading) {
    <mat-spinner diameter="24" matSuffix />
  }
</mat-form-field>
