import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { IndividualConfig, ToastrService } from 'ngx-toastr';

import { TNotification } from '@libs/core/types';

type TNotificationType = 'error' | 'info' | 'success';
type TNotificationConfig = Partial<IndividualConfig>;
type TNotificationConfigs = Record<
  TNotificationType,
  (message: TNotification, config?: TNotificationConfig) => void
>;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly _alertConfigs: TNotificationConfigs;

  constructor(
    private _toastr: ToastrService,
    private _translateService: TranslateService,
  ) {
    this._alertConfigs = {
      success: (message, config) => {
        this._toastr.success(this._getTranslation(message), undefined, config);
      },
      info: (message, config) => {
        this._toastr.info(this._getTranslation(message), undefined, config);
      },
      error: (message, config) => {
        this._toastr.error(this._getTranslation(message), undefined, config);
      },
    };
  }

  public success(message: TNotification, config?: TNotificationConfig): void {
    this._alertConfigs.success(message, config);
  }

  public error(message: TNotification, config?: TNotificationConfig): void {
    this._alertConfigs.error(message, config);
  }

  public info(message: TNotification, config?: TNotificationConfig): void {
    this._alertConfigs.info(message, config);
  }

  public clear(): void {
    this._toastr.clear();
  }

  private _getTranslation(message: TNotification): string {
    return typeof message === 'string'
      ? this._translateService.instant(message)
      : this._translateService.instant(message.text, message.values);
  }
}
