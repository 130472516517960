import { type Signal } from '@angular/core';

import type { OcpRequiredAndPartial } from '@ocp/utils/types';
import { OcpTooltipConfig } from '@ocp/ui-kit/tooltip';

import { OcpControl, type OcpControlProps } from '../models';

type OcpInputProps = OcpControlProps<string> &
  OcpRequiredAndPartial<
    OcpInput,
    never,
    'currentValue' | 'initialValue' | 'inputType' | 'onInput' | 'tooltipConfig'
  >;

export class OcpInput extends OcpControl<string> {
  type = 'input' as const;

  inputType?: 'input' | 'textarea' = 'input';

  initialValue?: Signal<string> | string;
  currentValue?: Signal<string>;
  tooltipConfig?: OcpTooltipConfig;

  onInput?: (value: string) => void;

  constructor(props: OcpInputProps) {
    super(props);
    Object.assign(this, props);
  }

  override reset(): void {
    this.reset$.next();
  }
}
