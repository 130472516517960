import { OcpButtonRealisation } from '@ocp/ui-kit/button';
import { OcpLabelConfig } from '@ocp/ui-kit/label';
import type { OcpRequiredAndPartial } from '@ocp/utils/types';

import { OcpDialogConfigBase, type OcpDialogConfigBaseProps } from '../../dialog';

export type OcpConfirmDialogConfigProps = OcpDialogConfigBaseProps &
  OcpRequiredAndPartial<OcpConfirmDialogConfig, 'inputs', never>;

export type OcpConfirmDialogInputs = {
  message: OcpLabelConfig;
  actions?: OcpButtonRealisation[];
};

export class OcpConfirmDialogConfig extends OcpDialogConfigBase {
  inputs!: OcpConfirmDialogInputs;

  constructor(props: OcpConfirmDialogConfigProps) {
    super(props);
    Object.assign(this, props);
  }

  setProps(props: Partial<OcpConfirmDialogConfigProps>): this {
    Object.assign(this, props);
    return this;
  }
}
