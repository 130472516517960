import { Injectable } from '@angular/core';

import {
  combineLatest,
  concat,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  type Observable,
  of,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { type ActionCreator, Store } from '@ngrx/store';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions } from '@libs/core/store';

import type {
  TDataModelClassListRequest,
  TDataModelClassListResponse,
  TDataModelClassQueryField,
  TDataModelClassSortField,
} from '../../types';
import { dataModelFeature } from '../data-model';
import { dataModelProjectFeature } from '../data-model-project';
import { dataModelVersionActions, dataModelVersionFeature } from '../data-model-version';
import { dataModelClassActions } from './data-model-class.actions';
import { DataModelWorkshopApiService } from '../../services';
import { dataModelClassFeature } from './data-model-class.reducer';

@Injectable({
  providedIn: 'root',
})
export class DataModelClassEffects {
  constructor(
    private _actions$: Actions,
    private _dataModelApiService: DataModelWorkshopApiService,
    private _store: Store,
  ) {}

  loadList$ = createEffect(() =>
    this._fetchList$(
      [dataModelClassActions.loadList],
      dataModelClassActions.loadListSuccess,
      dataModelClassActions.loadListFailure,
    ),
  );

  loadListWithForce$ = createEffect(() =>
    this._fetchList$(
      [dataModelClassActions.loadListWithForce],
      dataModelClassActions.loadListSuccess,
      dataModelClassActions.loadListFailure,
      true,
    ),
  );

  loadByFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelClassActions.setQueryData),
      map(() => dataModelClassActions.loadListWithForce()),
    ),
  );

  refreshList$ = createEffect(() =>
    this._fetchList$(
      [
        dataModelClassActions.setPageSize,
        dataModelClassActions.setPageIndex,
        dataModelClassActions.setSort,
      ],
      dataModelClassActions.refreshListSuccess,
      dataModelClassActions.refreshListFailure,
      true,
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => dataModelClassActions.resetState()),
    ),
  );

  changedParentSelect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelVersionActions.setSelectedItem),
      startWith(null),
      pairwise(),
      filter(([actionPrev, actionCurrent]) => {
        const isSameItem = actionPrev?.item?.id === actionCurrent?.item?.id;
        return !isSameItem; // Exclude if the selected item hasn't changed
      }),
      concatMap(([, actionCurrent]) => {
        const isItemCleared = !actionCurrent?.item;

        if (isItemCleared) {
          return of(dataModelClassActions.resetState());
        }

        return concat(of(dataModelClassActions.resetState()), of(dataModelClassActions.loadList()));
      }),
    ),
  );

  // TODO: Replace 'any' with proper types
  private _fetchList$(
    actions: ActionCreator[],
    successCallback: (response: { result: TDataModelClassListResponse }) => void,
    errorCallback: (error: any) => any,
    isForce = false,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectSelectedItem),
        this._store.select(dataModelVersionFeature.selectSelectedItem),
        this._store.select(dataModelClassFeature.selectDataModelClassState),
      ),
      switchMap(([, dmwProject, dataModel, dmwVersion, state]) => {
        const { records, sort, query, pagination } = state;
        const isDataExist = !isForce && records.length > 0;

        if (isDataExist || dmwProject == null || dataModel == null || dmwVersion == null) {
          // TODO: Implement error handling
          return EMPTY;
        }
        return this._dataModelApiService
          .getDataModelClassList$(
            {
              dmwProjectId: dmwProject.id,
              dataModelId: dataModel.id,
              dataModelVersionId: dmwVersion.id,
            },
            createListPayload<
              TDataModelClassSortField,
              TDataModelClassQueryField,
              TDataModelClassListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
            takeUntil(
              combineLatest([
                this._store.select(dataModelClassFeature.selectRefreshing),
                this._store.select(dataModelClassFeature.selectLoadingInitial),
              ]).pipe(
                filter(([refreshing, loadingInitial]) => !refreshing && !loadingInitial), // Stop when both are false
              ),
            ),
          );
      }),
    );
  }
}
