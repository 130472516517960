import { Injectable } from '@angular/core';

import { debounceTime, distinctUntilChanged, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { OcpUtilsObject } from '@ocp/utils/helpers';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { globalFeature } from '@libs/core/store';

import { PowerBiApiService } from '../services';
import { powerBiActions } from './power-bi.actions';

@Injectable({
  providedIn: 'root',
})
export class PowerBiEffects {
  constructor(
    private _actions$: Actions,
    private _powerBiApiService: PowerBiApiService,
    private _store: Store,
  ) {}

  loadConfigs$ = createEffect(() =>
    this._actions$.pipe(
      ofType(powerBiActions.loadConfigs),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(this._store.select(globalFeature.selectOpenedProjectId)),
      distinctUntilChanged((prev, curr) => OcpUtilsObject.equals(prev, curr)),
      switchMap(([, projectId]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._powerBiApiService.getConfigs$(projectId).pipe(
          map((response) => powerBiActions.loadConfigsSuccess({ response })),
          catchError((error) => of(powerBiActions.loadConfigsFailure({ error }))),
        );
      }),
    ),
  );
}
