import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(
    private readonly _iconRegistry: MatIconRegistry,
    private readonly _sanitizer: DomSanitizer,
  ) {}

  registerCustomSvgIcons(): void {
    // TODO: #NOT_OBVIOUS it loads data from assets of each project
    // If the structure will be changed for 1 project it will lead to collision
    this._iconRegistry.addSvgIconSet(
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/default-wireframe-set.svg'),
    );
  }
}
