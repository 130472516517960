import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { OcpLabelConfig } from '@ocp/ui-kit/label';
import type { OcpPartialExcept } from '@ocp/utils/types';

import {
  OcpConfirmDialogComponent,
  OcpConfirmDialogConfig,
  type OcpConfirmDialogConfigProps,
  type OcpConfirmDialogInputs,
} from '../confirm-dialog';
import { OcpDialogComponent, OcpDialogConfig } from '../dialog';

const OCP_DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  width: '400px',
  disableClose: true,
  hasBackdrop: true,
  panelClass: 'ocp-dialog-panel',
};

@Injectable({
  providedIn: 'root',
})
export class OcpDialogService {
  constructor(private _dialog: MatDialog) {}

  openDialog<TComponent, TReturn = unknown, TInputs = never>(
    data: OcpDialogConfig<TComponent, TInputs>,
    config?: Omit<MatDialogConfig<OcpDialogConfig<TComponent, TInputs>>, 'data'>,
  ): MatDialogRef<OcpDialogComponent<TComponent>, TReturn> {
    const panelClasses = [
      OCP_DEFAULT_DIALOG_CONFIG.panelClass,
      ...(Array.isArray(config?.panelClass) ? config.panelClass : [config?.panelClass]),
    ].filter(Boolean) as string[];

    const dialogConfig = {
      ...OCP_DEFAULT_DIALOG_CONFIG,
      ...config,
      data: data,
      panelClass: panelClasses,
    };
    return this._dialog.open(OcpDialogComponent<TComponent>, dialogConfig);
  }

  openConfirmDialog(
    dataProps: OcpConfirmDialogConfigProps,
    config?: Omit<MatDialogConfig<OcpDialogConfig<OcpConfirmDialogConfig>>, 'data'>,
  ): MatDialogRef<OcpDialogComponent<OcpConfirmDialogComponent>, false | true | undefined> {
    const panelClasses = [
      OCP_DEFAULT_DIALOG_CONFIG.panelClass,
      ...(Array.isArray(config?.panelClass) ? config.panelClass : [config?.panelClass]),
    ].filter(Boolean) as string[];
    const data = new OcpConfirmDialogConfig(dataProps);

    const dialogConfig: MatDialogConfig<
      OcpDialogConfig<OcpConfirmDialogComponent, OcpConfirmDialogInputs>
    > = {
      ...OCP_DEFAULT_DIALOG_CONFIG,
      ...config,
      data: {
        ...data,
        body: {
          component: OcpConfirmDialogComponent,
          inputs: data.inputs,
        },
      },
      panelClass: panelClasses,
    };
    return this._dialog.open(OcpDialogComponent<OcpConfirmDialogComponent>, dialogConfig);
  }

  openConfirmDialogWithDefaults(
    dataProps: OcpPartialExcept<OcpConfirmDialogConfigProps, 'translateSection'>,
    config?: Omit<MatDialogConfig<OcpDialogConfig<OcpConfirmDialogConfig>>, 'data'>,
  ): MatDialogRef<OcpDialogComponent<OcpConfirmDialogComponent>, false | true | undefined> {
    let dialogRef: MatDialogRef<
      OcpDialogComponent<OcpConfirmDialogComponent>,
      boolean | undefined
    > | null = null;

    const dataWithDefaults = new OcpConfirmDialogConfig({
      translateSection: '',
      titleConfig: new OcpLabelConfig({
        translate: true,
        typography: 'title-large',
        value: 'TITLE',
        htmlTag: 'h3',
      }),
      inputs: {
        message: new OcpLabelConfig({
          translate: true,
          value: 'MESSAGE',
        }),
        actions: [
          {
            actionsConfig: {
              callback: () => {
                dialogRef?.close(false);
              },
            },
            visualisationConfig: { appearance: 'basic', label: 'CANCEL' },
          },
          {
            actionsConfig: {
              callback: () => {
                dialogRef?.close(true);
              },
            },
            visualisationConfig: { appearance: 'flat', label: 'CONFIRM' },
          },
        ],
      },
    }).setProps(dataProps);

    dialogRef = this.openConfirmDialog(dataWithDefaults, config);

    return dialogRef;
  }

  openUnsavedChangesDialog(): MatDialogRef<
    OcpDialogComponent<OcpConfirmDialogComponent>,
    false | true | undefined
  > {
    let dialogRef: MatDialogRef<
      OcpDialogComponent<OcpConfirmDialogComponent>,
      boolean | undefined
    > | null = null;

    const dialogData = new OcpConfirmDialogConfig({
      translateSection: 'UNSAVED_CHANGES_DIALOG',
      titleConfig: new OcpLabelConfig({
        translate: true,
        typography: 'title-large',
        value: 'TITLE',
        htmlTag: 'h3',
        icon: {
          isCustom: true,
          color: 'error',
          icon: 'error',
        },
      }),
      inputs: {
        message: new OcpLabelConfig({
          translate: true,
          value: 'MESSAGE',
          insertAsHtml: true,
        }),
        actions: [
          {
            actionsConfig: {
              callback: () => {
                dialogRef?.close(false);
              },
            },
            visualisationConfig: { appearance: 'basic', label: 'CANCEL' },
          },
          {
            actionsConfig: {
              callback: () => {
                dialogRef?.close(true);
              },
            },
            visualisationConfig: { appearance: 'flat', label: 'CONFIRM' },
          },
        ],
      },
    });

    dialogRef = this.openConfirmDialog(dialogData);
    return dialogRef;
  }

  closeDialog<T, R = unknown>(ref: MatDialogRef<T, R>): void {
    ref.close();
  }
}
