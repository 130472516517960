import { Injectable } from '@angular/core';

import {
  combineLatest,
  concat,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  type Observable,
  of,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { type ActionCreator, Store } from '@ngrx/store';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions } from '@libs/core/store';

import type {
  TDataModelListRequest,
  TDataModelListResponse,
  TDataModelQueryField,
  TDataModelSortField,
} from '../../types';
import { dataModelProjectActions, dataModelProjectFeature } from '../data-model-project';
import { dataModelActions } from './data-model.actions';
import { DataModelWorkshopApiService } from '../../services';
import { dataModelFeature } from './data-model.reducer';

@Injectable({
  providedIn: 'root',
})
export class DataModelEffects {
  constructor(
    private _actions$: Actions,
    private _dataModelApiService: DataModelWorkshopApiService,
    private _store: Store,
  ) {}

  loadList$ = createEffect(() =>
    this._fetchList$(
      [dataModelActions.loadList],
      dataModelActions.loadListSuccess,
      dataModelActions.loadListFailure,
    ),
  );

  loadListByQuery$ = createEffect(() =>
    this._fetchList$(
      [dataModelActions.setQueryData],
      dataModelActions.loadListSuccess,
      dataModelActions.loadListFailure,
      true,
    ),
  );

  refreshList$ = createEffect(() =>
    this._fetchList$(
      [dataModelActions.setPageSize, dataModelActions.setPageIndex, dataModelActions.setSort],
      dataModelActions.refreshListSuccess,
      dataModelActions.refreshListFailure,
      true,
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => dataModelActions.resetState()),
    ),
  );

  changedParentSelect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelProjectActions.setSelectedItem),
      startWith(null),
      pairwise(),
      filter(([actionPrev, actionCurrent]) => {
        const isSameItem = actionPrev?.item?.id === actionCurrent?.item?.id;
        return !isSameItem; // Exclude if the selected item hasn't changed
      }),
      concatMap(([, actionCurrent]) => {
        const isItemCleared = !actionCurrent?.item;

        if (isItemCleared) {
          return of(dataModelActions.resetState());
        }

        return concat(of(dataModelActions.resetState()), of(dataModelActions.loadList()));
      }),
    ),
  );

  // TODO: Replace 'any' with proper types
  private _fetchList$(
    actions: ActionCreator[],
    successCallback: (response: { result: TDataModelListResponse }) => void,
    errorCallback: (error: any) => any,
    isForce = false,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectDataModelState),
      ),
      switchMap(([, dmwProject, state]) => {
        const { query, sort, records, pagination } = state;
        const isDataExist = !isForce && records.length > 0;

        if (isDataExist || dmwProject === null) {
          // TODO: Implement error handling
          return EMPTY;
        }
        return this._dataModelApiService
          .getDataModelList$(
            { dmwProjectId: dmwProject.id },
            createListPayload<TDataModelSortField, TDataModelQueryField, TDataModelListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
            takeUntil(
              combineLatest([
                this._store.select(dataModelFeature.selectRefreshing),
                this._store.select(dataModelFeature.selectLoadingInitial),
              ]).pipe(
                filter(([refreshing, loadingInitial]) => !refreshing && !loadingInitial), // Stop when both are false
              ),
            ),
          );
      }),
    );
  }
}
