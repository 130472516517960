import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  HostBinding,
  input,
  type OnInit,
  Signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { type OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpDialogConfig } from '@ocp/ui-kit/dialog/src/dialog/models';
import { OcpLabelComponent, OcpLabelConfig } from '@ocp/ui-kit/label';
import { OcpProgressSpinnerComponent } from '@ocp/ui-kit/progress-spinner';
import { OcpDialogService } from '@ocp/ui-kit/dialog';

import { CompanyFooterComponent } from '@libs/core-ui/footer';

import {
  ProjectCardsComponent,
  ProjectCreateComponent,
  ProjectPresentationConfig,
  ProjectTableComponent,
} from '../../components';
import { projectActions, projectFeature } from '../../store';
import type { TProject, TProjectCreateRequest } from '../../types';
import { ProjectListConfig, type TProjectListViewMode } from './models';
import { projectListActions, projectListFeature } from './store';

@Component({
  selector: 'lib-project-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
  imports: [
    CommonModule,
    MatButtonToggleModule,
    ProjectTableComponent,
    ProjectCardsComponent,
    FormsModule,
    OcpButtonUniversalComponent,
    OcpProgressSpinnerComponent,
    OcpTranslatePipe,
    OcpTranslationSectionDirective,
    CompanyFooterComponent,
    MatDivider,
    OcpLabelComponent,
  ],
})
export class ProjectListComponent implements OnInit {
  @HostBinding('class.parent-no-overflow') readonly hostCssClass = true;

  configSig = input.required<ProjectListConfig>({ alias: 'config' });

  viewModeSignal: Signal<TProjectListViewMode> = toSignal(
    this._store.select(projectListFeature.selectViewMode),
    {
      requireSync: true,
    },
  );
  projectsSignal: Signal<TProject[]> = toSignal(this._store.select(projectFeature.selectRecords), {
    requireSync: true,
  });
  isLoadingSignal: Signal<boolean> = toSignal(
    this._store.select(projectFeature.selectLoadingInitial),
    {
      requireSync: true,
    },
  );

  presentationConfig = new ProjectPresentationConfig({
    data: computed(() => this.projectsSignal()),
    onItemClick: (project) => this.configSig().onItemClick(project),
    onSettingsClick: (project) => this.configSig().onSettingsClick(project),
    onDeleteClick: (project) => this._deleteProject(project),
  });

  cardsModeButtonConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.handleViewModeChange('cards'),
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: {
        icon: 'grid_view',
      },
      color: computed(() => {
        return this.viewModeSignal() === 'cards' ? 'primary' : 'outline-variant';
      }),
    },
  };
  tableModeButtonConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.handleViewModeChange('table'),
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: {
        icon: 'lists',
        isCustom: true,
      },
      color: computed(() => {
        return this.viewModeSignal() === 'table' ? 'primary' : 'outline-variant';
      }),
    },
  };
  newProjectButtonConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this._createNewProject(),
    },
    visualisationConfig: {
      appearance: 'flat',
      classNames: ['toolbar-new-btn'],
      icon: {
        icon: 'add',
      },
      label: 'NEW',
    },
  };

  constructor(
    private readonly _store: Store,
    private readonly _dialogService: OcpDialogService,
    private readonly _destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this._store.dispatch(projectActions.loadList());
  }

  public deleteProject(project: TProject): void {
    this._store.dispatch(projectActions.deleteProject({ projectId: project.id }));
  }

  public handleViewModeChange(viewMode: TProjectListViewMode): void {
    this._store.dispatch(projectListActions.setViewMode({ viewMode }));
  }

  private _createNewProject(): void {
    const dialogRef = this._dialogService.openDialog<ProjectCreateComponent, TProjectCreateRequest>(
      new OcpDialogConfig({
        translateSection: 'PROJECT_CREATE_DIALOG',
        titleConfig: new OcpLabelConfig({
          value: 'TITLE',
          translate: true,
          typography: 'title-large',
          htmlTag: 'h3',
        }),
        body: {
          component: ProjectCreateComponent,
        },
      }),
    );

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((result?: TProjectCreateRequest) => {
        if (result == null) {
          return;
        }

        const settings = this.configSig().defaultSettings?.();

        this._store.dispatch(
          projectActions.createProject({
            payload: settings?.powerBI
              ? { ...result, settings: { powerBI: settings.powerBI } as any }
              : result,
          }),
        );
      });
  }

  private _deleteProject(project: TProject): void {
    this._dialogService
      .openConfirmDialogWithDefaults({
        translateSection: 'PROJECT_DELETE_DIALOG',
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((res?: boolean) => {
        if (res == null || !res) {
          return;
        }
        this.deleteProject(project);
      });
  }
}
