<ocp-button-universal
  (click)="$event.stopPropagation()"
  [matMenuTriggerFor]="menuActions"
  [config]="menuTriggerSig()"
/>

@let showSeparator = configSig().showSeparator;

<mat-menu
  [ocpTranslateSection]="configSig().translateSection"
  #menuActions
  [class]="panelClass"
  [xPosition]="configSig().position.x"
  [yPosition]="configSig().position.y"
>
  @for (menuConfig of configSig().menuItems; track $index) {
    @let config = menuConfig.item;

    @if (config.type === 'group') {
      @if (config.groupName) {
        <div mat-menu-item disabled class="menu-group">
          {{ config.groupName | ocpTranslate }}
        </div>
      } @else {
        <div class="menu-group"></div>
      }
      @for (item of config.items; track $index) {
        <ocp-menu-item [config]="item" />

        @if (showSeparator && !$last) {
          <mat-divider />
        }
      }
    } @else {
      <ocp-menu-item [config]="config" />

      @if (showSeparator && !$last) {
        <mat-divider />
      }
    }
  }
</mat-menu>
