import type { Signal } from '@angular/core';

import type { OcpButtonRealisation } from '@ocp/ui-kit/button';
import type { OcpRequiredAndPartial } from '@ocp/utils/types';

type OcpMenuCheckboxItem = {
  type: 'checkbox';
  label: string;
  checked: Signal<boolean>;
  disabled?: Signal<boolean>;
  classNames?: string[];
  classNamesDynamic?: Signal<string[]>;
  callback: () => void;
};

type OcpMenuButtonItem = OcpButtonRealisation & {
  type: 'button';
};

export type OcpMenuActionItem = OcpMenuButtonItem | OcpMenuCheckboxItem;

type OcpMenuActionGroup = {
  groupName: string;
  items: OcpMenuActionItem[];
  type: 'group';
};

type OcpMenuItemConfigItem = OcpMenuActionGroup | OcpMenuActionItem;

export type OcpMenuItemConfigProps = OcpRequiredAndPartial<OcpMenuItemConfig, 'item', never>;

export class OcpMenuItemConfig {
  item!: OcpMenuItemConfigItem;

  constructor(props: OcpMenuItemConfigProps) {
    Object.assign(this, props);
  }
}
