import { signal, type Signal } from '@angular/core';

import type { OcpRequiredAndPartial } from '@ocp/utils/types';

type OcpProgressBarConfigProps = OcpRequiredAndPartial<OcpProgressBarConfig, never, 'isVisible'>;

export class OcpProgressBarConfig {
  // TODO: #LOW #UI_FEAT add other props from mat progress bar

  isVisible: Signal<boolean> = signal(true);

  constructor(props?: OcpProgressBarConfigProps) {
    props && Object.assign(this, props);
  }
}
