import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';

import { OcpProgressBarConfig } from './models';

@Component({
  selector: 'ocp-progress-bar',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
  imports: [MatProgressBar],
})
export class OcpProgressBarComponent {
  configSig = input<OcpProgressBarConfig>(new OcpProgressBarConfig(), { alias: 'config' });
}
