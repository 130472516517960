import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { type OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpForm, OcpFormComponent, OcpInput } from '@ocp/ui-kit/form';

import type { TCreateDialogForm } from './project-create-form.type';

@Component({
  selector: 'lib-project-create',
  standalone: true,
  templateUrl: './project-create.component.html',
  styleUrl: './project-create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OcpFormComponent, OcpButtonUniversalComponent],
})
export class ProjectCreateComponent {
  newProjectForm = new OcpForm<TCreateDialogForm>();

  cancelBtnConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.dialogRef.close(),
    },
    visualisationConfig: {
      appearance: 'basic',
      label: 'CANCEL',
    },
  };
  submitBtnConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this._onSubmit(),
    },
    visualisationConfig: {
      appearance: 'flat',
      label: 'SUBMIT',
    },
  };

  constructor(public dialogRef: MatDialogRef<ProjectCreateComponent>) {
    this.newProjectForm.setControls({
      name: new OcpInput({
        formControl: new FormControl('', Validators.required),
        label: 'PROJECT_NAME',
        objectId: crypto.randomUUID(),
      }),
      description: new OcpInput({
        objectId: crypto.randomUUID(),
        label: 'PROJECT_DESCRIPTION',
      }),
    });
  }

  private _onSubmit(): void {
    const formGroup = this.newProjectForm.getFormGroup();
    if (formGroup.valid) {
      this.dialogRef.close(formGroup.getRawValue());
    }
  }
}
