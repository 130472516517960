import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';

import { OcpIconBasicComponent } from '@ocp/ui-kit/icon';
import { OcpLabelComponent } from '@ocp/ui-kit/label';
import { OcpMergeArraysPipe } from '@ocp/utils/pipes';
import { OcpTranslatePipe } from '@ocp/fusion-cdk/translate';

import { type OcpMenuActionItem } from './models';

@Component({
  selector: 'ocp-menu-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss',
  imports: [
    MatMenuItem,
    OcpIconBasicComponent,
    OcpMergeArraysPipe,
    OcpTranslatePipe,
    MatTooltip,
    NgClass,
    MatCheckbox,
    OcpLabelComponent,
  ],
})
export class OcpMenuItemComponent {
  @HostBinding('class.ocp-menu-item') hostCssClass = true;

  configSig = input.required<OcpMenuActionItem>({ alias: 'config' });
}
