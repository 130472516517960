import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { ENVIRONMENT_SERVICE_TOKEN } from '@libs/core/environment';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type {
  TAttributeListRequest,
  TAttributeListResponse,
  TAttributeLocationListRequest,
  TAttributeLocationListResponse,
  TAttributePropertyListRequest,
  TAttributePropertyListResponse,
} from '../types';

@Injectable({ providedIn: 'root' })
export class AttributeApiService {
  private readonly _environmentService = inject(ENVIRONMENT_SERVICE_TOKEN);

  private readonly _urlBuilder = new OcpUrlBuilder(
    `${this._environmentService.getProp('API_PREFIX')}/projects`,
  );

  constructor(private readonly _http: HttpClient) {}

  public getList$(
    projectId: number,
    payload?: TAttributeListRequest,
  ): Observable<TAttributeListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'attributes', 'list']);

    return this._http.post<TAttributeListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['AttributeListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['AttributeListResponse'],
      }),
    });
  }

  public getLocationList$(
    projectId: number,
    payload?: TAttributeLocationListRequest,
  ): Observable<TAttributeLocationListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'attributes', 'locations', 'list']);

    return this._http.post<TAttributeLocationListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['AttributeLocationListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['AttributeLocationListResponse'],
      }),
    });
  }

  public getListProperty$(
    projectId: number,
    payload?: TAttributePropertyListRequest,
  ): Observable<TAttributePropertyListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'attributes', 'property']);

    return this._http.post<TAttributePropertyListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['AttributePropertyListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['PropertyListResponse'],
      }),
    });
  }
}
