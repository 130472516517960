import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private _translate: TranslateService) {}

  public initialize(): void {
    this._translate.setDefaultLang('en');
    this._translate.use('en').subscribe();
  }
}
