import type { ComponentType } from '@angular/cdk/portal';

import type { OcpLabelConfig } from '@ocp/ui-kit/label';
import type { OcpRequiredAndPartial } from '@ocp/utils/types';

export type OcpDialogConfigBaseProps = OcpRequiredAndPartial<
  OcpDialogConfigBase,
  never,
  'showClose' | 'showSeparator' | 'titleConfig' | 'translateSection'
>;

type OcpDialogConfigProps<TComponent, TInputs> = OcpDialogConfigBaseProps &
  OcpRequiredAndPartial<OcpDialogConfig<TComponent, TInputs>, 'body', never>;

type OcpDialogBody<TComponent, TInputs = never> = {
  component: ComponentType<TComponent>;
  inputs?: TInputs;
};

export class OcpDialogConfigBase {
  translateSection = '';
  titleConfig?: OcpLabelConfig;
  showClose = false;
  showSeparator = true;

  constructor(props: OcpDialogConfigBaseProps) {
    Object.assign(this, props);
  }
}

export class OcpDialogConfig<TComponent, TInputs = never> extends OcpDialogConfigBase {
  body!: TComponent extends never ? never : OcpDialogBody<TComponent, TInputs>;

  constructor(props: OcpDialogConfigProps<TComponent, TInputs>) {
    super(props);
    Object.assign(this, props);
  }
}
