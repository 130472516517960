import type { MenuPositionX, MenuPositionY } from '@angular/material/menu';

import type { OcpIconBasic } from '@ocp/ui-kit/icon';
import type { OcpOverrideProps, OcpRequiredAndPartial } from '@ocp/utils/types';

import { OcpMenuItemConfig, type OcpMenuItemConfigProps } from '../menu-item';

type OcpActionsMenuConfigProps = OcpOverrideProps<
  OcpRequiredAndPartial<
    OcpActionMenuConfig,
    'menuItems',
    'position' | 'showSeparator' | 'translateSection' | 'triggerIcon'
  >,
  {
    menuItems: OcpMenuItemConfigProps[];
  }
>;

export class OcpActionMenuConfig {
  menuItems!: OcpMenuItemConfig[];

  triggerIcon: OcpIconBasic;
  translateSection: string;
  showSeparator: boolean;
  position: { x: MenuPositionX; y: MenuPositionY };

  constructor(props: OcpActionsMenuConfigProps) {
    this.menuItems = props.menuItems.map((item) => new OcpMenuItemConfig(item));
    this.triggerIcon = props.triggerIcon ?? { icon: 'menu' };
    this.translateSection = props.translateSection ?? '';
    this.position = props.position ?? {
      x: 'after',
      y: 'below',
    };
    this.showSeparator = props.showSeparator ?? false;
  }
}
