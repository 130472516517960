import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { type OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpTooltipConfig } from '@ocp/ui-kit/tooltip';
import { OcpSanitizeImagePipe } from '@ocp/utils/pipes';
import { OcpActionMenuConfig, OcpActionsMenuComponent } from '@ocp/ui-kit/menu';
import { OcpInput, OcpInputComponent } from '@ocp/ui-kit/form';
import {
  OcpProgressSpinnerComponent,
  OcpProgressSpinnerConfig,
} from '@ocp/ui-kit/progress-spinner';
import { OcpUtilsDate } from '@ocp/utils-advanced/helpers';

import type { TProject } from '../../types';
import type { TProjectCardConfig } from './project-card-config.type';

const TOOLTIP_SHOW_DELAY = 800;

@Component({
  selector: 'lib-project-card',
  standalone: true,
  templateUrl: './project-card.component.html',
  styleUrl: './project-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatMenuModule,
    NgOptimizedImage,
    CommonModule,
    OcpTranslationSectionDirective,
    OcpButtonUniversalComponent,
    OcpTranslatePipe,
    OcpSanitizeImagePipe,
    OcpActionsMenuComponent,
    OcpInputComponent,
    OcpProgressSpinnerComponent,
  ],
})
export class ProjectCardComponent {
  projectSig = input.required<TProject>({ alias: 'project' });
  prioritizeImageLoadingSig = input(false, { alias: 'prioritizeImageLoading' });
  configSig = input.required<TProjectCardConfig>({ alias: 'config' });

  fallbackImageSrc = 'assets/images/project-preview-placeholder.png';
  cardFieldsSig = computed<OcpInput[]>(() => this._initializeCardFields(this.projectSig()));
  spinnerConfig = new OcpProgressSpinnerConfig({
    isShowOverlay: true,
    isVisible: computed(() => Boolean(this.projectSig().isDeleting)),
  });

  openProjectBtnConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.handleOpenProjectClick(this.projectSig()),
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: {
        icon: 'arrow_forward',
      },
      classNames: ['open-project-button', 'primary'],
    },
  };

  menuConfig = new OcpActionMenuConfig({
    triggerIcon: { icon: 'more_horiz', color: 'on-surface-variant' },
    showSeparator: true,
    position: { x: 'before', y: 'below' },
    menuItems: [
      {
        item: {
          actionsConfig: {
            callback: () => this.configSig().onItemClick(this.projectSig()),
          },
          visualisationConfig: {
            appearance: 'menu',
            icon: {
              icon: 'ocp_arrow_circle_right',
              isCustom: true,
            },
            label: 'OPEN_BUTTON',
          },
          type: 'button',
        },
      },
      {
        item: {
          actionsConfig: {
            callback: () => this.configSig().onSettingsClick(this.projectSig()),
          },
          visualisationConfig: {
            appearance: 'menu',
            icon: {
              icon: 'settings',
            },
            label: 'SETTINGS_BUTTON',
          },
          type: 'button',
        },
      },
      // {
      //   item: {
      //     actionsConfig: {
      //       callback: () => {},
      //     },
      //     visualisationConfig: {
      //       appearance: 'menu',
      //       icon: {
      //         icon: 'share',
      //       },
      //       label: 'SHARE_BUTTON',
      //     },
      //     type: 'button',
      //   },
      // },
      {
        item: {
          actionsConfig: {
            callback: () => this.configSig().onDeleteClick(this.projectSig()),
          },
          visualisationConfig: {
            appearance: 'menu',
            icon: {
              icon: 'delete',
              color: 'error',
            },
            label: 'DELETE_BUTTON',
          },
          type: 'button',
        },
      },
    ],
  });

  handleOpenProjectClick(project: TProject): void {
    this.configSig().onItemClick(project);
  }

  private _initializeCardFields(project: TProject): OcpInput[] {
    const fields = [
      new OcpInput({
        label: 'AUTHOR',
        formControl: new FormControl(project.ownerUserName),
      }),
      new OcpInput({
        label: 'CREATION_DATE',
        formControl: new FormControl(OcpUtilsDate.formatWithKeyword(project.createdAt, 'short')),
      }),
      new OcpInput({
        label: 'DESCRIPTION',
        formControl: new FormControl(project.description ?? ''),
      }),
    ];

    fields.forEach((el) => {
      el.readonly = true;
      el.tooltipConfig = new OcpTooltipConfig({
        text: el.formControl.value ?? '',
        showDelay: TOOLTIP_SHOW_DELAY,
      });
    });

    return fields;
  }
}
