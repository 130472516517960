import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { ENVIRONMENT_SERVICE_TOKEN } from '@libs/core/environment';
import { createHttpContext } from '@libs/core/utils';

import { TPowerBIEmbedConfigResponse } from '../types';

@Injectable({ providedIn: 'root' })
export class PowerBiApiService {
  private readonly _environmentService = inject(ENVIRONMENT_SERVICE_TOKEN);

  private readonly _urlBuilder = new OcpUrlBuilder(
    `${this._environmentService.getProp('API_PREFIX')}/projects`,
  );

  constructor(private readonly _http: HttpClient) {}

  public getConfigs$(projectId: number): Observable<TPowerBIEmbedConfigResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'bi', 'powerbi']);
    return this._http.get<TPowerBIEmbedConfigResponse>(url, {
      context: createHttpContext({
        VALIDATE_REQUEST: false,
        // TODO: #OPENAPI_BUG Backend Response should not be declared inline in the endpoint
        // Schema should be Record<string, EmbedConfigDto>
        // RESPONSE_SCHEMA: SCHEMAS['EmbedConfigDto']
      }),
    });
  }
}
