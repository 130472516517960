import { Injectable } from '@angular/core';

import {
  catchError,
  debounceTime,
  filter,
  map,
  type Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import type {
  TAttributeLocationListRequest,
  TAttributeLocationListResponse,
  TAttributeLocationQueryField,
  TAttributeLocationSortField,
} from '../types';
import { AttributeLocationApiService } from '../services';
import { attributeLocationFeature } from './attribute-location.reducer';
import { attributeLocationActions } from './attribute-location.actions';

@Injectable({ providedIn: 'root' })
export class AttributeLocationEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _attributeLocationApiService: AttributeLocationApiService,
  ) {}

  loadAttributesLocation$ = createEffect(() =>
    this._fetchAttributes$(
      [
        attributeLocationActions.setQueryData,
        attributeLocationActions.resetQueryFilter,
        attributeLocationActions.setQueryFilterDisabled,
      ],
      attributeLocationActions.loadListSuccess,
      attributeLocationActions.loadListFailure,
    ),
  );

  refreshAttributesLocation$ = createEffect(() =>
    this._fetchAttributes$(
      [
        attributeLocationActions.setPageSize,
        attributeLocationActions.setPageIndex,
        attributeLocationActions.setSort,
      ],
      attributeLocationActions.refreshListSuccess,
      attributeLocationActions.refreshListFailure,
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(attributeLocationActions.loadList),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(attributeLocationFeature.selectPagination),
        this._store.select(attributeLocationFeature.selectSort),
        this._store.select(attributeLocationFeature.selectQuery),
        this._store.select(attributeLocationFeature.selectRecords),
      ),
      switchMap(([, projectId, pagination, sort, query, records]) => {
        if (projectId === null || Boolean(records.length)) {
          return of();
        }
        return this._attributeLocationApiService
          .getLocationList$(
            projectId,
            createListPayload<
              TAttributeLocationSortField,
              TAttributeLocationQueryField,
              TAttributeLocationListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => attributeLocationActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(attributeLocationActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => attributeLocationActions.resetState()),
    ),
  );

  private _fetchAttributes$(
    actions: ActionCreator[],
    successCallback: (response: { result: TAttributeLocationListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(attributeLocationFeature.selectPagination),
        this._store.select(attributeLocationFeature.selectSort),
        this._store.select(attributeLocationFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._attributeLocationApiService
          .getLocationList$(
            projectId,
            createListPayload<
              TAttributeLocationSortField,
              TAttributeLocationQueryField,
              TAttributeLocationListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
