import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { ATTRIBUTE_LOCATION_STORE_OPTIONS } from './attribute-store-options.constant';
import {
  TAttributeLocation,
  TAttributeLocationQueryField,
  TAttributeLocationSortField,
} from '../types';

export const attributeLocationActions = createActionGroup({
  source: 'AttributeLocation',
  events: {
    ...getEntityListActions<
      TAttributeLocation,
      TAttributeLocationQueryField,
      TAttributeLocationSortField
    >()(ATTRIBUTE_LOCATION_STORE_OPTIONS),
  },
});
