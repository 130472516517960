import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { ENVIRONMENT_SERVICE_TOKEN } from '@libs/core/environment';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type {
  TDocumentJobListRequest,
  TDocumentJobListResponse,
  TDocumentJobStartRequest,
  TDocumentJobStartResponse,
} from '../types';

const NOTIFICATION_MSG_PATH = 'DOCUMENT_JOB.API';

@Injectable({ providedIn: 'root' })
export class DocumentJobApiService {
  private readonly _environmentService = inject(ENVIRONMENT_SERVICE_TOKEN);

  private readonly _urlBuilder = new OcpUrlBuilder(
    `${this._environmentService.getProp('API_PREFIX')}/projects`,
  );

  constructor(private readonly _http: HttpClient) {}

  public getList$(
    projectId: number,
    payload?: TDocumentJobListRequest,
  ): Observable<TDocumentJobListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'document-jobs', 'list']);

    return this._http.post<TDocumentJobListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['DocumentJobListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['DocumentJobListResponse'],
      }),
    });
  }

  public startJobs$(
    projectId: number,
    payload: TDocumentJobStartRequest,
  ): Observable<TDocumentJobStartResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'run']);

    return this._http.post<TDocumentJobStartResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['JobDataDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
        SUCCESS_NOTIFICATION: `${NOTIFICATION_MSG_PATH}.DOCUMENT_JOB_START_SUCCESS`,
        FAILURE_NOTIFICATION: `${NOTIFICATION_MSG_PATH}.DOCUMENT_JOB_START_FAILURE`,
      }),
    });
  }
}
