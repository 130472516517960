import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpLabelComponent } from '@ocp/ui-kit/label';
import { OcpComponentDirective } from '@ocp/ui-kit/utils';

import { OcpDialogConfig } from './models/dialog-config.model';

@Component({
  selector: 'ocp-dialog',
  standalone: true,
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogContent,
    MatDialogActions,
    OcpButtonUniversalComponent,
    OcpTranslatePipe,
    MatDialogTitle,
    OcpTranslationSectionDirective,
    OcpComponentDirective,
    MatDialogClose,
    MatDivider,
    NgComponentOutlet,
  ],
})
export class OcpDialogComponent<T> {
  config = inject<OcpDialogConfig<T>>(MAT_DIALOG_DATA);
  public readonly OcpLabelBasicComponent = OcpLabelComponent;

  public closeDialogButtonConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.dialogRef.close(undefined),
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: {
        icon: 'close',
      },
    },
  };

  constructor(public dialogRef: MatDialogRef<OcpDialogComponent<T>>) {}
}
