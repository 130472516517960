@let config = configSig();

@if (config.type === 'button') {
  @if (config.actionsConfig.isShowed?.() ?? true) {
    <button
      mat-menu-item
      [disabled]="config.actionsConfig.disabled?.() ?? false"
      [matTooltip]="config.visualisationConfig.tooltip | ocpTranslate"
      [ngClass]="
        config.visualisationConfig.classNames
          | ocpMergeArrays: config.visualisationConfig.classNamesDynamic?.()
      "
      (click)="config.actionsConfig.callback()"
      (keydown)="config.actionsConfig.callback()"
    >
      @let icon = config.visualisationConfig.icon;
      @let label = config.visualisationConfig.label;

      <ocp-label [icon]="icon" [value]="label" translate />
      <!--      @if {-->
      <!--        <ocp-icon-basic [iconData]="config.visualisationConfig.icon!"></ocp-icon-basic>-->
      <!--      }-->
      <!--      @if (config.visualisationConfig.label) {-->
      <!--        {{ config.visualisationConfig.label | ocpTranslate }}-->
      <!--      }-->
      @if (config.visualisationConfig.classNames?.includes('selected')) {
        <ocp-icon-basic [iconData]="{ icon: 'check', classNames: ['primary'] }" />
      }
    </button>
  }
}

@if (config.type === 'checkbox') {
  <button
    mat-menu-item
    [disabled]="config.disabled?.() ?? false"
    [ngClass]="config.classNames | ocpMergeArrays: config.classNamesDynamic?.()"
    (click)="$event.stopPropagation()"
  >
    <mat-checkbox [checked]="config.checked()" (change)="config.callback()">
      {{ config.label | ocpTranslate }}
      @if (config.checked()) {
        <ocp-icon-basic [iconData]="{ icon: 'check', classNames: ['primary'] }"></ocp-icon-basic>
      }
    </mat-checkbox>
  </button>
}
