import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { OcpSearchConfig } from '@ocp/ui-kit/search';
import { OcpTableFeaturedComponent, type OcpTableFeaturedConfig } from '@ocp/ui-kit/table';
import { type OcpPaginationEvent, OcpPaginatorStandardConfig } from '@ocp/ui-kit/paginator';
import { OcpUtilsDate } from '@ocp/utils-advanced/helpers';
import { OcpScheduler } from '@ocp/utils/models';
import { OcpTranslatePipe } from '@ocp/fusion-cdk/translate';

import { DEFAULT_TABLE_CELL_EMPTY_VALUE } from '@libs/core/constants';

import { taskJobActions, taskJobFeature } from '../../store';
import { TTaskJob, TTaskJobQueryField, TTaskJobSortField } from '../../types';
import type { TTaskJobTableColumnKey } from './models';
import { DEFAULT_TASK_JOB_TABLE_SEARCH_CONFIG, TASK_JOB_STATUS_TO_COLOR_CONFIG } from './constants';

@Component({
  selector: 'lib-task-job',
  standalone: true,
  templateUrl: './task-job-table.component.html',
  styleUrl: './task-job-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OcpTableFeaturedComponent],
})
export class TaskJobTableComponent implements OnInit, OnDestroy {
  public tableConfig!: OcpTableFeaturedConfig<
    TTaskJob,
    TTaskJobTableColumnKey,
    TTaskJobQueryField,
    TTaskJobSortField
  >;

  private _scheduleHandler = new OcpScheduler(
    () => this._store.dispatch(taskJobActions.loadListWithForce()),
    2000,
  );

  private _taskJobs$ = this._store.select(taskJobFeature.selectRecords);

  constructor(private readonly _store: Store) {
    this._initializeTableOptions();
    this._initializeTablePaginator();
    this._initializeTableSearch();

    this._scheduleHandler.listen(
      this._taskJobs$.pipe(map((jobs) => jobs.some((job) => job.status === 'RUNNING'))),
      this._store.select(taskJobFeature.selectLoadingInitial),
    );
  }

  ngOnInit(): void {
    this._store.dispatch(taskJobActions.loadList());
  }

  ngOnDestroy(): void {
    this._scheduleHandler.destroy();
  }

  private _initializeTableOptions(): void {
    this.tableConfig = {
      tableConfig: {
        data: toSignal(this._store.select(taskJobFeature.selectRecords), { requireSync: true }),
        translateSection: 'TASK_JOB_TABLE',
        titleKey: 'HEADER_TITLE',
        cellEmptyValue: DEFAULT_TABLE_CELL_EMPTY_VALUE,
        sortConfig: {
          sort: toSignal(this._store.select(taskJobFeature.selectSort), { requireSync: true }),
          onSortChange: (sort) =>
            this._store.dispatch(taskJobActions.setSort({ sort, refreshBehavior: 'replace' })),
        },
        loadingInitial: toSignal(this._store.select(taskJobFeature.selectLoadingInitial), {
          requireSync: true,
        }),
        refreshing: toSignal(this._store.select(taskJobFeature.selectRefreshing), {
          requireSync: true,
        }),
        columns: [
          {
            def: 'name',
            header: 'NAME',
            isSortable: true,
            getValue: (taskJob: TTaskJob) => taskJob.name,
          },
          {
            def: 'datamodelName',
            header: 'DATAMODEL_NAME',
            isSortable: true,
            getValue: (taskJob: TTaskJob) => taskJob.datamodelName,
          },
          {
            def: 'datamodelVersionName',
            header: 'DATAMODEL_VERSION_NAME',
            isSortable: true,
            getValue: (taskJob: TTaskJob) => taskJob.datamodelVersionName,
          },
          {
            def: 'triggeredAt',
            header: 'TRIGGERED_AT',
            isSortable: true,
            getValue: (taskJob: TTaskJob) =>
              OcpUtilsDate.formatWithKeyword(taskJob.triggeredAt, 'short'),
          },
          {
            def: 'status',
            header: 'STATUS',
            isSortable: true,
            getValue: (taskJob: TTaskJob) => taskJob.status,
            pipe: OcpTranslatePipe,
            getColor: (taskJob: TTaskJob) =>
              TASK_JOB_STATUS_TO_COLOR_CONFIG[taskJob.status].labelColor,
            typography: 'title-small',
          },
          {
            type: 'progress',
            def: 'progress',
            header: 'PROGRESS',
            getValue: (taskJob: TTaskJob) => Number(taskJob.progress),
            getProgressBarColor: (taskJob: TTaskJob) =>
              TASK_JOB_STATUS_TO_COLOR_CONFIG[taskJob.status].progressBarColor,
          },
        ],
        actionColumn: {
          def: 'actions',
          header: '',
          data: [
            {
              iconData: {
                icon: 'cancel',
              },
              tooltip: 'CANCEL_BUTTON_TOOLTIP',
              getDisabled: (taskJob: TTaskJob) => taskJob.status !== 'RUNNING',
              action: (taskJob: TTaskJob) =>
                this._store.dispatch(
                  taskJobActions.cancelJobs({
                    payload: {
                      ids: [taskJob.id.toString()],
                    },
                  }),
                ),
            },
            {
              iconData: {
                icon: 'restart_alt',
              },
              tooltip: 'RESTART_BUTTON_TOOLTIP',
              getDisabled: (taskJob: TTaskJob) =>
                !(taskJob.status === 'FAILED' || taskJob.status === 'CANCELLED'),
              action: (taskJob: TTaskJob) =>
                this._store.dispatch(
                  taskJobActions.retryJobs({
                    payload: {
                      ids: [taskJob.id.toString()],
                    },
                  }),
                ),
            },
          ],
        },
      },
    };
  }

  private _initializeTablePaginator(): void {
    this.tableConfig.paginationConfig = {
      type: 'standard',
      config: new OcpPaginatorStandardConfig({
        pagination: toSignal(this._store.select(taskJobFeature.selectPagination), {
          requireSync: true,
        }),
        onPaginationChange: (event) => this._handlePaginationChange(event),
      }),
    };
  }

  private _initializeTableSearch(): void {
    this.tableConfig.searchConfig = new OcpSearchConfig<TTaskJobQueryField>({
      ...DEFAULT_TASK_JOB_TABLE_SEARCH_CONFIG,
      query: toSignal(this._store.select(taskJobFeature.selectQuery), { requireSync: true }),
      onQueryDataChange: (data) =>
        this._store.dispatch(taskJobActions.setQueryData({ queryData: data })),
      onResetFilter: () => this._store.dispatch(taskJobActions.resetQueryFilter()),
      onToggleFilterDisableState: (isDisabled) =>
        this._store.dispatch(taskJobActions.setQueryFilterDisabled({ isDisabled })),
    });
  }

  private _handlePaginationChange(event: OcpPaginationEvent): void {
    switch (event.type) {
      case 'pageSize':
        this._store.dispatch(
          taskJobActions.setPageSize({
            pageSize: event.value,
            refreshBehavior: event.behavior,
          }),
        );
        break;
      case 'pageIndex':
        this._store.dispatch(
          taskJobActions.setPageIndex({
            pageIndex: event.value,
            refreshBehavior: event.behavior,
          }),
        );
        break;
    }
  }
}
