import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Directive, effect, input, ViewContainerRef } from '@angular/core';

import type { OcpComponentRegistry } from '../types';

@Directive({
  selector: '[ocpComponent]',
  standalone: true,
})
export class OcpComponentDirective<
  TComponent = OcpComponentRegistry['component'],
  TConfig extends object = OcpComponentRegistry['config'],
> {
  component = input.required<ComponentType<TComponent>>({ alias: 'ocpComponent' });
  config = input.required<TConfig>({ alias: 'ocpComponentConfig' });

  private _componentRef: ComponentRef<TComponent> | null = null;

  constructor(private _viewContainerRef: ViewContainerRef) {
    effect(() => {
      const currentConfig = this.config();
      const currentComponent = this.component();

      if (!this._componentRef || this._componentRef.componentType !== currentComponent) {
        this._createComponent(currentComponent);
      }

      this._updateInputs(currentConfig);
    });
  }

  private _createComponent(component: ComponentType<TComponent>): void {
    this._viewContainerRef.clear(); // Clear previous components
    this._componentRef = this._viewContainerRef.createComponent(component);
  }

  private _updateInputs(config: TConfig): void {
    if (!this._componentRef) {
      console.warn('No component instance available to update inputs.');
      return;
    }

    Object.entries(config).forEach(([key, value]) => {
      this._componentRef?.setInput(key, value);
    });
  }
}
