import { ChangeDetectionStrategy, Component, computed, HostBinding, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';

import { OcpActionMenuConfig } from './models';
import { OcpMenuItemComponent } from './menu-item';

@Component({
  selector: 'ocp-actions-menu',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './actions-menu.component.html',
  styleUrl: './actions-menu.component.scss',
  imports: [
    MatButtonModule,
    MatMenuModule,
    OcpTranslationSectionDirective,
    OcpTranslatePipe,
    OcpButtonUniversalComponent,
    OcpMenuItemComponent,
    MatDivider,
  ],
})
export class OcpActionsMenuComponent {
  @HostBinding('class.ocp-menu') hostCssClass = true;

  configSig = input.required<OcpActionMenuConfig>({ alias: 'config' });

  readonly panelClass = 'ocp-menu-panel';

  menuTriggerSig = computed<OcpButtonRealisation>(() => ({
    actionsConfig: {
      callback: () => {
        // empty
      },
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: this.configSig().triggerIcon,
      classNames: ['mdc-icon-button', 'white'],
      color: this.configSig().triggerIcon.color,
    },
  }));
}
