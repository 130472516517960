import { createFeature, createReducer, on } from '@ngrx/store';

import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';
import type { OcpStoreEntityList } from '@ocp/utils-advanced/types';
import type { OcpQueryData } from '@ocp/utils/types';

import { ATTRIBUTE_LOCATION_STORE_OPTIONS } from './attribute-store-options.constant';
import type {
  TAttributeLocation,
  TAttributeLocationQueryField,
  TAttributeLocationSortField,
} from '../types';
import { attributeLocationActions } from './attribute-location.actions';

type TInitialState = OcpStoreEntityList<
  TAttributeLocation,
  typeof ATTRIBUTE_LOCATION_STORE_OPTIONS,
  TAttributeLocationQueryField,
  TAttributeLocationSortField
>;

const defaultQueryData: OcpQueryData<TAttributeLocationQueryField>[] = [
  {
    type: 'CONTAINS',
    field: 'name',
    q: '',
  },
];

const defaultState = getDefaultStoreEntityList<
  TAttributeLocation,
  TAttributeLocationQueryField,
  TAttributeLocationSortField
>()(ATTRIBUTE_LOCATION_STORE_OPTIONS);

const initialState: TInitialState = {
  ...defaultState,
  query: {
    ...defaultState.query,
    data: defaultQueryData,
  },
  sort: { by: 'name', order: 'ASC' },
};

const defaultHandlers = getEntityListHandlers<
  TAttributeLocation,
  object,
  TAttributeLocationQueryField,
  TAttributeLocationSortField
>()(ATTRIBUTE_LOCATION_STORE_OPTIONS);

const attributeLocationReducer = createReducer(
  initialState,
  on(attributeLocationActions.loadList, (state) => defaultHandlers.loadList(state)),
  on(attributeLocationActions.loadListSuccess, (state, { result: response }) =>
    defaultHandlers.loadListSuccess(state, response),
  ),
  on(attributeLocationActions.loadListFailure, (state, { error }) =>
    defaultHandlers.loadListFailure(state, error),
  ),
  on(attributeLocationActions.refreshListSuccess, (state, { result: response }) =>
    defaultHandlers.refreshListSuccess(state, response),
  ),
  on(attributeLocationActions.refreshListFailure, (state, { error }) =>
    defaultHandlers.refreshListFailure(state, error),
  ),
  on(attributeLocationActions.setPageSize, (state, { pageSize, refreshBehavior }) =>
    defaultHandlers.setPageSize(state, pageSize, refreshBehavior),
  ),
  on(attributeLocationActions.setPageIndex, (state, { pageIndex, refreshBehavior }) =>
    defaultHandlers.setPageIndex(state, pageIndex, refreshBehavior),
  ),
  on(attributeLocationActions.setSort, (state, { sort, refreshBehavior }) =>
    defaultHandlers.setSort(state, sort, refreshBehavior),
  ),
  on(attributeLocationActions.setQueryData, (state, { queryData }) =>
    defaultHandlers.setQueryData(state, queryData, defaultQueryData),
  ),
  on(attributeLocationActions.resetState, () => initialState),
  on(attributeLocationActions.resetQueryFilter, (state) =>
    defaultHandlers.resetQueryFilter(state, defaultQueryData),
  ),
  on(attributeLocationActions.setQueryFilterDisabled, (state, { isDisabled }) =>
    defaultHandlers.setQueryFilterDisabled(state, isDisabled, defaultQueryData),
  ),
);

export const attributeLocationFeature = createFeature({
  name: 'attributeLocation',
  reducer: attributeLocationReducer,
});
