import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  HostBinding,
  input,
  type OnDestroy,
  type OnInit,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatOption } from '@angular/material/autocomplete';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { OcpTranslatePipe } from '@ocp/fusion-cdk/translate';
import { OcpUtilsSignal } from '@ocp/utils/helpers';
import { OcpUtilityDomService } from '@ocp/utils/services';

import type { OcpControlInitialValue } from '../types';
import type { OcpAutocompleteFlatConfig } from './autocomplete-config.model';

const OCP_FORM_FIELD_READONLY_CLASS = 'ocp-form-field-readonly';
const OCP_FORM_FIELD_HIDE_ERROR_CLASS = 'hide-error';

// TODO: #UI_FEAT Add element selection by ENTER if the value of the input is equal to one of the values
// It should select the first value
// TODO: #TBD Should the value to be selected even if it's partially met the value
//  (i.e. The project name "Test project", user enter "Test" and press ENTER)
@Component({
  standalone: true,
  selector: 'ocp-autocomplete-flat',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './autocomplete-flat.component.html',
  styleUrl: './autocomplete-flat.component.scss',
  providers: [OcpUtilityDomService],
  imports: [
    MatFormField,
    MatLabel,
    OcpTranslatePipe,
    MatAutocompleteModule,
    MatOption,
    ReactiveFormsModule,
    MatInput,
    MatProgressSpinner,
    MatSuffix,
  ],
})
export class OcpAutocompleteFlatComponent<TEntity> implements OnInit, OnDestroy {
  @HostBinding('class.ocp-autocomplete-flat') hostCssClass = true;
  public readonly panelClass = 'ocp-autocomplete-flat-panel';

  configSig = input.required<OcpAutocompleteFlatConfig<TEntity>>({ alias: 'config' });

  public autocomplete = viewChild<MatAutocomplete>(MatAutocomplete);
  public isReadonly = computed(() =>
    Boolean(
      this.configSig().readonly || (this.configSig().isDataLoading?.() && !this._isInputValue()),
    ),
  );
  public isDataEmpty = computed(
    () => this.configSig().data().length === 0 && !this._isInputValue(),
  );

  private _isInputValue = signal(false);

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _utilityDomService: OcpUtilityDomService,
    private _destroyRef: DestroyRef,
  ) {
    effect(() => {
      const isReadOnly = this.isReadonly();

      this._utilityDomService.updateClass(
        this._elementRef,
        OCP_FORM_FIELD_READONLY_CLASS,
        isReadOnly,
      );
    });

    effect(() => {
      this._utilityDomService.updateClass(
        this._elementRef,
        OCP_FORM_FIELD_HIDE_ERROR_CLASS,
        this.configSig().appearanceParams.hideError ?? false,
      );
    });

    effect(() => {
      const control = this.configSig().formControl;
      const isControlDisabled = this.configSig().disabled();
      const isReadOnly = this.isReadonly();
      const isDataEmpty = this.isDataEmpty();

      isReadOnly || isDataEmpty || isControlDisabled
        ? control.disable({ emitEvent: false })
        : control.enable({ emitEvent: false });
    });

    effect(() => {
      const { data, initialValue, currentValue, formControl } = this.configSig();
      const dataValue = data();
      const isInputValue = untracked(this._isInputValue);

      if (!isInputValue && !dataValue.length) {
        this._resetControlValue();
        return;
      }

      const current = OcpUtilsSignal.extract(currentValue, false);
      const initial = OcpUtilsSignal.extract(initialValue, false);

      const valueToSet = current ?? (formControl.untouched ? initial : null);

      if (valueToSet) {
        this._setControlValue(valueToSet, dataValue);
      }
    });

    effect(() => {
      const { data, initialValue } = this.configSig();
      const valueToFind = OcpUtilsSignal.extract(initialValue);

      this._setControlValue(valueToFind, untracked(data));
    });

    effect(() => {
      const { currentValue, data } = this.configSig();
      const value = currentValue?.();

      value?.value != null
        ? this._setControlValue(value, untracked(data))
        : this._resetControlValue();
    });

    effect((onCleanup) => {
      const { reset$, initialValue, data } = this.configSig();

      const resetSub = reset$.subscribe(() => {
        const valueToFind = OcpUtilsSignal.extract(initialValue, false);

        valueToFind?.value != null
          ? this._setControlValue(valueToFind, untracked(data))
          : this._resetControlValue();
      });
      onCleanup(() => resetSub.unsubscribe());
    });
  }

  ngOnInit(): void {
    this.configSig().onInit?.();

    this.configSig()
      .formControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value: TEntity | string | null) => {
        if (typeof value === 'string') {
          // input value if requireSelection is not set
          return;
        }
        this._isInputValue.set(false);
        // Handle select change
        this.configSig().onSelect?.(value);
      });
  }

  ngOnDestroy(): void {
    this.configSig().onDestroy?.();
  }

  // Handle input changes
  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this._isInputValue.set(true);
    this.configSig().onInput?.(inputValue);
  }

  private _toggleSelectedOption(value: TEntity | null, isToSelect: boolean): void {
    const autocomplete = this.autocomplete();

    if (!autocomplete) {
      return;
    }

    autocomplete.options.forEach((option) => {
      if (value != null && this.configSig().compareFn(option.value, value)) {
        isToSelect ? option.select() : option.deselect();
        return;
      }

      if (option.selected) {
        option.deselect();
      }
    });
  }

  private _resetControlValue(): void {
    const { formControl } = this.configSig();
    formControl.reset(null);
    setTimeout(() => this._toggleSelectedOption(null, false), 0);
  }

  private _setControlValue(value: OcpControlInitialValue<TEntity> | null, data: TEntity[]): void {
    const { formControl, compareFn } = this.configSig();

    if (value == null || value.value == null) {
      return;
    }

    let valueToSet: TEntity | undefined;

    if (value.type === 'property') {
      valueToSet = data.find((el) => el[value.prop] === value.value);
    } else {
      valueToSet = data.find((el) => compareFn(el, value.value ?? null));
    }

    if (valueToSet != null && !compareFn(formControl.value, valueToSet)) {
      formControl.reset(valueToSet, { onlySelf: true });
      // TODO: #UI_FEAT update behavior so we start calling method after autocomplete options exists
      setTimeout(() => this._toggleSelectedOption(valueToSet, true), 0);
    }
  }
}
