@let config = configSig();
@let item = itemSig();
@let icon = config.getIcon?.(item);
@let value = config.getValue(item) | ocpDynamicPipe: config.pipe : config.pipeArgs;

<div class="container">
  @if (icon) {
    <ocp-icon-basic [iconData]="icon"></ocp-icon-basic>
  }
  @switch (config.type) {
    @case ('progress') {
      @let progressBarColor = config.getProgressBarColor?.(item);

      <mat-progress-bar
        [matTooltip]="value / 100 | percent"
        [value]="value"
        [color]="progressBarColor"
      />
    }
    @default {
      @let color = config.getColor?.(item);

      <ocp-label
        [value]="value ?? defaultValueSig()"
        [typography]="config.typography || 'body-medium'"
        [color]="color ?? 'on-surface'"
      />
    }
  }
</div>
