import {
  HttpEvent,
  type HttpHandlerFn,
  type HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import type { ZodSchema } from 'zod';

import { ENVIRONMENT_SERVICE_TOKEN } from '@libs/core/environment';
import {
  REQUEST_SCHEMA_TOKEN,
  RESPONSE_SCHEMA_TOKEN,
  VALIDATE_REQUEST_TOKEN,
  VALIDATE_RESPONSE_TOKEN,
} from '@libs/core/tokens';

const ENDPOINTS_TO_SKIP_VALIDATION = ['/assets/', 'assets/'];

export const zodValidationInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const environmentService = inject(ENVIRONMENT_SERVICE_TOKEN);

  const shouldUseValidation = environmentService.getProp('USE_ZOD_VALIDATION');

  // Skip validation for asset calls
  if (
    !shouldUseValidation ||
    ENDPOINTS_TO_SKIP_VALIDATION.some((url) => request.url.includes(url))
  ) {
    return next(request);
  }

  // Get the context data for request validation
  const requestSchema = request.context.get(REQUEST_SCHEMA_TOKEN);
  const responseSchema = request.context.get(RESPONSE_SCHEMA_TOKEN);
  const shouldValidateRequest = request.context.get(VALIDATE_REQUEST_TOKEN);
  const shouldValidateResponse = request.context.get(VALIDATE_RESPONSE_TOKEN);

  // Ensure that schema is provided if validation is enabled
  if (shouldValidateRequest && !requestSchema) {
    return throwError(
      () => new Error('Request schema must be provided when request validation is enabled.'),
    );
  }
  if (shouldValidateResponse && !responseSchema) {
    return throwError(
      () => new Error('Response schema must be provided when response validation is enabled.'),
    );
  }

  // Validate the request if specified
  if (shouldValidateRequest && requestSchema) {
    validateRequest(request, requestSchema);
  }

  // Handle the request and validate the response if specified
  return next(request).pipe(
    tap((event) => {
      if (event instanceof HttpResponse && shouldValidateResponse && responseSchema) {
        validateResponse(event, responseSchema);
      }
    }),
  );
};

function validateRequest(request: HttpRequest<any>, requestSchema: ZodSchema) {
  try {
    requestSchema.parse(request.body);
    console.log('Request validation successful!');
  } catch (e) {
    if (e instanceof Error && 'errors' in e) {
      throw new Error(
        `Request validation error: ${(e as any).errors.map((err: any) => err.message).join(', ')}`,
      );
    } else {
      throw new Error('Unknown request validation error occurred.');
    }
  }
}

function validateResponse(response: HttpResponse<any>, responseSchema: ZodSchema) {
  try {
    responseSchema.parse(response.body);
    console.log('Response validation successful!');
  } catch (e) {
    if (e instanceof Error && 'errors' in e) {
      console.error(
        'Response validation error:',
        (e as any).errors.map((err: any) => err.message).join(', '),
      );
    } else {
      console.error('Unknown response validation error occurred.');
    }
  }
}
