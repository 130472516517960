@let appearance = configSig().appearance;
@let label = configSig().label;
@let formControl = configSig().formControl;
@let readonly = configSig().readonly;
@let tooltipConfig = configSig().tooltipConfig;

<mat-form-field [appearance]="appearance" class="field">
  @if (label) {
    <mat-label>{{ label | ocpTranslate }}</mat-label>
  }

  @switch (configSig().inputType) {
    @case ('textarea') {
      <textarea
        matInput
        [formControl]="formControl"
        (input)="onInputChange($event)"
        [matTooltip]="tooltipConfig?.text"
        [matTooltipShowDelay]="tooltipConfig?.showDelay"
        (click)="readonly && $event.preventDefault()"
        rows="3"
      ></textarea>
    }
    @case ('input') {
      <input
        matInput
        [formControl]="formControl"
        (input)="onInputChange($event)"
        [matTooltip]="tooltipConfig?.text"
        [matTooltipShowDelay]="tooltipConfig?.showDelay"
        (click)="readonly && $event.preventDefault()"
        type="text"
      />
    }
  }
</mat-form-field>
