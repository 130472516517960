import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';

import { OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpLabelComponent } from '@ocp/ui-kit/label';
import { OcpComponentDirective } from '@ocp/ui-kit/utils';

import { OcpConfirmDialogInputs } from './models';

export type OcpConfirmDialogData = {
  title: string;
  message: string;
  actionButtons?: OcpButtonRealisation[];
};

@Component({
  selector: 'ocp-confirm-dialog',
  standalone: true,
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OcpButtonUniversalComponent, OcpComponentDirective],
})
export class OcpConfirmDialogComponent {
  @HostBinding('class.ocp-confirm-dialog') hostCssClass = true;

  message = input.required<OcpConfirmDialogInputs['message']>();
  actions = input<OcpConfirmDialogInputs['actions']>([]);

  constructor() {}

  protected readonly OcpLabelBasicComponent = OcpLabelComponent;
}
