import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { ENVIRONMENT_SERVICE_TOKEN } from '@libs/core/environment';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type {
  TTagListBatchRequest,
  TTagListBatchResponse,
  TTagListRequest,
  TTagListResponse,
  TTagLocationListRequest,
  TTagLocationListResponse,
  TTagPropertyListBatchRequest,
  TTagPropertyListBatchResponse,
  TTagPropertyListRequest,
  TTagPropertyListResponse,
} from '../types';

@Injectable({ providedIn: 'root' })
export class TagApiService {
  private readonly _environmentService = inject(ENVIRONMENT_SERVICE_TOKEN);

  private readonly _urlBuilder = new OcpUrlBuilder(
    `${this._environmentService.getProp('API_PREFIX')}/projects`,
  );

  constructor(private readonly _http: HttpClient) {}

  public getList$(projectId: number, payload?: TTagListRequest): Observable<TTagListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'tags', 'list']);

    return this._http.post<TTagListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TagListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['TagListResponse'],
      }),
    });
  }

  public getListBatch$(
    projectId: number,
    payload?: TTagListBatchRequest,
  ): Observable<TTagListBatchResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'tags', 'list', 'batch']);

    return this._http.post<TTagListBatchResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TagListBatchRequest'],
        RESPONSE_SCHEMA: SCHEMAS['TagListBatchResponse'],
      }),
    });
  }

  public getLocationList$(
    projectId: number,
    payload?: TTagLocationListRequest,
  ): Observable<TTagLocationListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'tags', 'locations', 'list']);

    return this._http.post<TTagLocationListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TagLocationListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['TagLocationListResponse'],
      }),
    });
  }

  // TODO: #HOTFIX replace Response type with more accurate
  public getPropertyList$(
    projectId: number,
    payload?: TTagPropertyListRequest,
  ): Observable<TTagPropertyListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'tags', 'property']);

    return this._http.post<TTagPropertyListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TagListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['TagListResponse'],
      }),
    });
  }

  public getPropertyListBatch$(
    projectId: number,
    payload?: TTagPropertyListBatchRequest,
  ): Observable<TTagPropertyListBatchResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'tags', 'property', 'batch']);

    return this._http.post<TTagPropertyListBatchResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TagPropertyListBatchRequest'],
        RESPONSE_SCHEMA: SCHEMAS['PropertyListBatchResponse'],
      }),
    });
  }
}
