import { Injectable } from '@angular/core';

import { tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { OcpDialogConfig } from '@ocp/ui-kit/dialog/src/dialog';
import { OcpLabelConfig } from '@ocp/ui-kit/label';
import { OcpDialogService } from '@ocp/ui-kit/dialog';

import { TaskManagerComponent } from '../containers/';
import { taskManagerActions } from './task-manager.actions';

@Injectable({ providedIn: 'root' })
export class TaskManagerEffects {
  constructor(
    private _actions$: Actions,
    private _ocpDialogService: OcpDialogService,
  ) {}

  openTaskManagerDialog$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(taskManagerActions.openDialog),
        tap(() =>
          this._ocpDialogService.openDialog(
            new OcpDialogConfig({
              translateSection: 'TASK_MANAGEMENT',
              showClose: true,
              titleConfig: new OcpLabelConfig({
                value: 'DIALOG_TITLE',
                translate: true,
                htmlTag: 'h3',
                typography: 'title-large',
              }),
              body: {
                component: TaskManagerComponent,
              },
            }),
            {
              maxHeight: '90vh',
              maxWidth: '90vw',
              width: '100%',
              height: 'auto',
            },
          ),
        ),
      );
    },
    { dispatch: false },
  );
}
