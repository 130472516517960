import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { ENVIRONMENT_SERVICE_TOKEN } from '@libs/core/environment';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type {
  TTaskJobActionCommonResponse,
  TTaskJobCancelRequest,
  TTaskJobListRequest,
  TTaskJobListResponse,
  TTaskJobRetryRequest,
  TTaskJobStartRequest,
} from '../types';

const NOTIFICATION_MSG_PATH = 'TASK_JOB.API';

@Injectable({ providedIn: 'root' })
export class TaskJobApiService {
  private readonly _environmentService = inject(ENVIRONMENT_SERVICE_TOKEN);

  private readonly _urlBuilder = new OcpUrlBuilder(
    `${this._environmentService.getProp('API_PREFIX')}/projects`,
  );

  constructor(private readonly _http: HttpClient) {}

  public getList$(
    projectId: number,
    payload?: TTaskJobListRequest,
  ): Observable<TTaskJobListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'list']);
    return this._http.post<TTaskJobListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['JobListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['SwaggerCustomPageJobDto'],
      }),
    });
  }

  public startJobs$(
    projectId: number,
    payload: TTaskJobStartRequest,
  ): Observable<TTaskJobActionCommonResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'run']);

    return this._http.post<TTaskJobActionCommonResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['JobDataDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
        SUCCESS_NOTIFICATION: `${NOTIFICATION_MSG_PATH}.JOB_START_SUCCESS`,
        FAILURE_NOTIFICATION: `${NOTIFICATION_MSG_PATH}.JOB_START_FAILURE`,
      }),
    });
  }

  public retryJobs$(
    projectId: number,
    payload: TTaskJobRetryRequest,
  ): Observable<TTaskJobActionCommonResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'retry']);

    return this._http.post<TTaskJobActionCommonResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TaskListDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
      }),
    });
  }

  public cancelJobs$(
    projectId: number,
    payload: TTaskJobCancelRequest,
  ): Observable<TTaskJobActionCommonResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'cancel']);

    return this._http.post<TTaskJobActionCommonResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TaskListDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
      }),
    });
  }
}
