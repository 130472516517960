import type { Signal } from '@angular/core';

import type { OcpRequiredAndPartial } from '@ocp/utils/types';

import type { TProjectSettings } from '@libs/modules/project-settings';

import { TProject } from '../../../types';

type TProjectListConfigProps = OcpRequiredAndPartial<
  ProjectListConfig,
  'onItemClick' | 'onSettingsClick',
  'defaultSettings'
>;

export class ProjectListConfig {
  onItemClick!: (item: TProject) => void;
  onSettingsClick!: (item: TProject) => void;
  defaultSettings?: Signal<TProjectSettings>;

  constructor(props: TProjectListConfigProps) {
    Object.assign(this, props);
  }
}
