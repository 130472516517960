import { OcpRequiredAndPartial } from '@ocp/utils/types';

type OcpTooltipConfigProps = OcpRequiredAndPartial<OcpTooltipConfig, 'text', 'showDelay'>;

export class OcpTooltipConfig {
  text!: string;
  showDelay?: number = 0;

  constructor(props: OcpTooltipConfigProps) {
    Object.assign(this, props);
  }
}
