import { PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTooltip } from '@angular/material/tooltip';

import { OcpLabelComponent } from '@ocp/ui-kit/label';
import { OcpDynamicPipe } from '@ocp/utils/pipes';
import { OcpIconBasicComponent } from '@ocp/ui-kit/icon';

import { OcpTableBasicCell } from '../types';

@Component({
  selector: 'ocp-table-cell',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './table-cell.component.html',
  styleUrl: './table-cell.component.scss',
  imports: [
    OcpIconBasicComponent,
    OcpDynamicPipe,
    MatProgressBar,
    OcpLabelComponent,
    MatTooltip,
    PercentPipe,
  ],
})
export class OcpTableCellComponent<TItem = unknown> {
  @HostBinding('class.ocp-table-basic-cell') hostCssClass = true;

  configSig = input.required<OcpTableBasicCell<TItem>>({ alias: 'config' });
  itemSig = input.required<TItem>({ alias: 'item' });
  defaultValueSig = input.required<string>({ alias: 'defaultValue' });
}
