import { Injectable } from '@angular/core';

import { combineLatest, EMPTY, filter, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { authActions } from '@libs/modules/auth';

import { dataModelProjectFeature } from './data-model-project.reducer';
import { dataModelProjectActions } from './data-model-project.actions';
import { DataModelWorkshopApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class DataModelProjectEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _dataModelApiService: DataModelWorkshopApiService,
  ) {}

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelProjectActions.loadList),
      withLatestFrom(this._store.select(dataModelProjectFeature.selectRecords)),
      switchMap(([, records]) => {
        if (records.length) {
          return EMPTY;
        }
        return this._dataModelApiService.getDataModelProjectList$().pipe(
          map((response) =>
            dataModelProjectActions.loadListSuccess({
              result: response,
            }),
          ),
          catchError((error) => {
            return of(dataModelProjectActions.loadListFailure({ error }));
          }),
          takeUntil(
            combineLatest([
              this._store.select(dataModelProjectFeature.selectRefreshing),
              this._store.select(dataModelProjectFeature.selectLoadingInitial),
            ]).pipe(
              filter(([refreshing, loadingInitial]) => !refreshing && !loadingInitial), // Stop when both are false
            ),
          ),
        );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.logout),
      map(() => dataModelProjectActions.resetState()),
    ),
  );
}
