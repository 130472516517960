import { OcpProgressBarColor } from '@ocp/ui-kit/progress-bar';
import { OcpLabelColor } from '@ocp/ui-kit/label';

import type { TTaskJobStatus } from '../../../types/task-job.type';

type TStatusToColorMap = {
  progressBarColor: OcpProgressBarColor;
  labelColor: OcpLabelColor;
};

export const TASK_JOB_STATUS_TO_COLOR_CONFIG: Record<TTaskJobStatus, TStatusToColorMap> = {
  CANCELLED: {
    progressBarColor: 'surface-dim',
    labelColor: 'on-surface',
  },
  FAILED: {
    progressBarColor: 'error',
    labelColor: 'error',
  },
  NOSTATUS: {
    progressBarColor: 'surface-dim',
    labelColor: 'on-surface',
  },
  PARTIAL_SUCCESS: {
    progressBarColor: 'warn',
    labelColor: 'warn',
  },
  PAUSED: {
    progressBarColor: 'surface-dim',
    labelColor: 'on-surface',
  },
  QUEUED: {
    progressBarColor: 'surface-dim',
    labelColor: 'on-surface',
  },
  RESCHEDULED: {
    progressBarColor: 'primary',
    labelColor: 'primary',
  },
  RETRY: {
    progressBarColor: 'surface-dim',
    labelColor: 'on-surface',
  },
  RUNNING: {
    progressBarColor: 'primary',
    labelColor: 'primary',
  },
  SCHEDULED: {
    progressBarColor: 'primary',
    labelColor: 'primary',
  },
  SKIPPED: {
    progressBarColor: 'surface-dim',
    labelColor: 'on-surface',
  },
  SUCCESS: {
    progressBarColor: 'success',
    labelColor: 'success',
  },
};
